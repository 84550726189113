import axios from 'axios'
import { REACT_APP_API_URL, IDENTITY_REALM, IDENTITY_URL, FOLDER_LOCATION, SCAN_APP_API_URL } from '../env'
import UserService from './userService'
import moment from 'moment'

/**
 * Class API
 * @version 1.0.0
 */
class API {
    /**
     * Get api url.
     *
     * @version 1.0.0
     */
    get apiUrl() {
        return REACT_APP_API_URL
    }

    /**
     * Constructor.
     * @version 1.0.0
     */
    constructor() {
        // this.handleErrors = this.handleErrors.bind(this);
        this.backendUrl = 'https://wender.podo-it.nl'
        this.sharepointToken = ''
        this.accountLinkUrl = ''
        this.expires_at = ''
    }

    /**
     * Handle the retrieved error from the API
     *
     * @param {Object} errors - object with error information
     */
    handleASPErrors(errors) {
        try {
            if (errors) {
                const message = ['The following errors occurred:']
                for (const key in errors) {
                    const errs = errors[key]
                    message.push(`  ${key}`)
                    for (const value of errs) {
                        message.push(`   - ${value}`)
                    }
                }
                console.error('ASP.NET\n', message.join('\n'))
            }
        } catch (e) {
            console.error('Response.errors is not as was expected', e, errors)
        }
    }

    /**
     * Handles shared internal logic for requests.
     * The response can be handled using both awaiting the result and using promises
     *
     * @param  {string   } method    The method, GET, POST, you name it.
     * @param  {string   } path      The path of the request. Only requests to
     * @param  {Object   } body      The body of the request. This will be turned into JSON automatically.
     * @param  {Object   } query     The query of the request.
     * @param  {Function?} onSuccess The callback called on a successful fetch. it is optional.
     * @param  {Function?} onError   The callback called when an error occurs.  it is optional.
     * @return {Promise<Object>} a promise with the json content
     */
    async JSONRequest(method, path, body, onSuccess, onError) {
        if (this.backendUrl === '') {
            onError('Geen backend geselecteerd')
            return
        }

        try {
            const response = await axios({
                method,
                url: `${this.apiUrl}${path}`,
                data: body,
                headers: {
                    Token: UserService.getToken(),
                    Backend: this.backendUrl,
                    Realm: IDENTITY_REALM,
                    KeycloakEndpoint: IDENTITY_URL
                }
            })
            if (onSuccess) onSuccess(response.data)
            return response
        } catch (error) {
            if (error?.response?.status === 400) {
                this.handleASPErrors(error.response.data.errors)
            }
            if (onError) onError(error)
        }
    }

    /**
     * A simplified version of {@link #JSONRequest} for get requests. can be awaited
     * @param   {String   } path      The path of the request. e.g. /Items
     * @param   {Function?} onSuccess A callback called if the request was successful. This is optional.
     * @param   {Function?} onError   A callback called if the request failed.         This is optional.
     * @returns {Promise<Object>} a promise with the json content
     */
    JSONGet(path, onSuccess, onError) {
        return this.JSONRequest('GET', path, {}, onSuccess, onError)
    }

    /**
     * Get patients.
     *
     * @param {Function} onSuccess - callback function.
     * @param {Function} onError - error callback function.
     * @param {FormData} patienformDatatNumber - number of patient
     * @version 1.0.0
     */
    async postInsole(formData, onSuccess, onError) {
        return this.JSONRequest('POST', '/podoit/unity', formData, onSuccess, onError)
    }

    /**
     *
     * @param {*} formData
     * @returns
     */
    async testPS2D(formData) {
        try {
            const response = await axios({
                method: 'post',
                url: SCAN_APP_API_URL + '/objconvert',
                data: formData,
                headers: {
                    Authorization: 'Bearer ' + UserService.getToken()
                }
            })
            return response
        } catch (error) {
            if (error?.response?.status === 400) {
                this.handleASPErrors(error.response.data.errors)
            }
        }
    }

    /**
     * Get patients.
     *
     * @param {Function} onSuccess - callback function.
     * @param {Function} onError - error callback function.
     * @param {Number} patientNumber - number of patient
     * @version 1.0.0
     */
    patients(patientNumber, onSuccess, onError) {
        return this.JSONGet(`/podoit/patient/${patientNumber}`, onSuccess, onError)
    }

    /**
     * Get therapist.
     *
     * @param {Function} onSuccess - callback function.
     * @param {Function} onError - error callback function.
     * @version 1.0.0
     */
    therapists(onSuccess, onFailure) {
        return this.JSONGet('/podoit/therapist', onSuccess, onFailure)
    }

    /**
     *
     * @param {*} callback -
     * @param {*} errorCallback -
     */
    location(callback, errorCallback) {
        return this.JSONGet('/podoit/location', callback, errorCallback)
    }

    /**
     * Places a new order. can be awaited
     * @param order the data of the order to place
     * @param onSuccess
     * @param onError
     * @returns {Promise<Object>}
     */
    placeOrder(order, onSuccess, onError) {
        if (order.orderType === 'fits' || order.orderType === 'fitsCustom') {
            return this.JSONRequest('POST', '/podoit/order/fits', order, onSuccess, onError)
        } else {
            return this.JSONRequest('POST', '/podoit/order/insole', order, onSuccess, onError)
        }
    }

    /**
     * Gets the orders from the server. can be awaited
     * @param {Object} patientID the id of the patient for the order to get
     * @param {Function?} onSuccess a callback for success
     * @param {Function?} onError   a callback for errors
     * @returns {Promise<Object>} response
     */
    checkAccess(patientID, onSuccess, onError) {
        return this.JSONGet(`/podoit/patient/checkaccess/${patientID}`, onSuccess, onError)
    }

    /**
     * Gets the orders from the server. can be awaited
     * @param {Object} patientID the id of the patient for the order to get
     * @param {Function?} onSuccess a callback for success
     * @param {Function?} onError   a callback for errors
     * @returns {Promise<Object>} response
     */
    getOrder(patientID, onSuccess, onError) {
        return this.JSONGet(`/podoit/order/${patientID}`, onSuccess, onError)
    }

    /**
     * Update the backend URL
     *
     * @param {String} backend - the selected backend url
     */
    setBackend(backend) {
        this.backendUrl = backend
    }

    /**
     * Processes the Microsoft response for SharePoint integration, manages token updates, 
     * and handles redirections for account linking.
     *
     * @function setSharepointInformation
     * @param {string} microsoftResponse - The JSON string response from the Microsoft Graph API.
     * @param {string} [patientnumber] - Optional patient number to include in the redirect URI.
     * @param {URLSearchParams} queryParameters - The query parameters from the current URL.
     * @returns {void}
     */
    setSharepointInformation(microsoftResponse, queryParameters, patientnumber) {
        const nr = patientnumber !== undefined ? patientnumber : ''
        const response = JSON.parse(microsoftResponse)

        if (!UserService.hasRole('sharepoint-tester')) return

        if (response.error_description === 'linked token is expired' && queryParameters.get('redirected') === null) {
            window.location.replace(`${response['account-link-url']}&redirect_uri=https://${window.location.host}/${nr}?redirected=true`)
            return
        }

        this.sharepointToken = response.access_token
        this.accountLinkUrl = `${response['account-link-url']}&redirect_uri=https://${window.location.host}`

        const exp = response.expires_in * 1000
        const date = moment.now()
        this.expires_at = exp + date

        if (response.expires_in < 2400 && queryParameters.get('redirected') === null) {
            window.location.replace(`${response['account-link-url']}&redirect_uri=https://${window.location.host}/${nr}?redirected=true`)
        }
    }

    /**
     * Uploads a file to SharePoint using Microsoft Graph API.
     * 
     * @async
     * @function uploadToSharepoint
     * @param {Buffer|Blob} file - The file content to upload. It should be in binary format.
     * @param {string} fileName - The name of the file to be uploaded, including its extension.
     * @param {Function} [onSuccess] - Optional callback function to handle successful uploads. Receives the response data as an argument.
     * @param {Function} [onError] - Optional callback function to handle errors during upload. Receives the error object as an argument.
     * @returns {Promise<Object>} - Resolves with the response from the Microsoft Graph API containing file metadata on success.
     * @throws {Error} - Throws an error if the upload fails and no `onError` callback is provided.
     * 
     * @example
     * const fileContent = Buffer.from("example content");
     * const fileName = "example.zip";
     * 
     * uploadToSharepoint(fileContent, fileName, 
     *   (data) => console.log("Upload successful:", data), 
     *   (error) => console.error("Upload failed:", error)
     * );
     */
    async uploadToSharepoint(file, fileName, fileExtension, onSuccess, onError) {
        let folder = FOLDER_LOCATION
        if(UserService.hasRole('ppc-tester')){
            folder = `Gemodelleerd - SLIM/${fileName}`
        }
        try {
            const response = await axios({
                method: 'put',
                url: `https://graph.microsoft.com/v1.0/sites/voetencentrumwender590.sharepoint.com,5db3cbfd-afb9-4e2c-bebf-bf20b096ff68,27b33d2a-8432-41a2-926d-18a075b384c9/drives/b!_cuzXbmvLE6-v78gsJb_aCo9sycyhKJBkm0YoHWzhMkH9h1t788xSYzPfgPpKPWP/root:/Centraal modelleren (project)/ExportsWebapplicatieSlimModelleren/${folder}/${fileName}${fileExtension}:/content`,
                maxBodyLength: Infinity,
                headers: {
                    'Content-Type': 'application/zip',
                    Authorization: 'Bearer ' + this.sharepointToken
                },
                data: file
            })
            if (onSuccess) onSuccess(response.data)
            return response
        } catch (e) {
            onError(e)
        }
    }
}


export default (new API())
