import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateAllFields, validateMinMax, validateRelief, validateASCDiff, validatePelotte, validatePelotteSuggestion, tafelbergCheck, validateFacsiASC, validateAscPadFasciPad, validateConflictsInsoleElements, validateNeskridCoverMaterial, validateFitsAsc, validateMic } from './validation.js'

const soleResultSlice = createSlice({
    name: 'soleResult',
    initialState: [
        { ...initialState },
        { ...initialState },
        { ...initialState }
    ],
    reducers: {
        soleResultData (state, action) {
            const side = action.payload.side
            const soleResNumber = action.payload.soleResNumber
            const key = action.payload.key
            const value = action.payload.value
            switch (action.payload.key) {
            case 'MIC':
                state[soleResNumber].soleResultData[side].MIC = isNaN(value) ? '' : value
                validateMinMax(state, action)
                tafelbergCheck(state, action)
                validateMic(state, action)
                break
            case 'MICType':
                state[soleResNumber].soleResultData[side].MICType = value
                break
            case 'MICPosition':
                state[soleResNumber].soleResultData[side].MICPosition = value
                break
            case 'pelotteType':
                state[soleResNumber].soleResultData[side].pelotteType = value
                validatePelotte(state, soleResNumber, side, value)
                break
            case 'ASC':
                state[soleResNumber].soleResultData[side].ASC = isNaN(value) ? '' : value
                validateMinMax(state, action)
                validateASCDiff(state, action)
                validateFacsiASC(state, action)
                validateFitsAsc(state, action)
                validateConflictsInsoleElements(state, action)
                tafelbergCheck(state, action)
                break
            case 'fasciPad':
                state[soleResNumber].soleResultData[side][key] = action.payload.value
                validateFacsiASC(state, action)
                validateAscPadFasciPad(state, action)
                break
            case 'ascPad':
                state[soleResNumber].soleResultData[side][key] = action.payload.value
                validateFacsiASC(state, action)
                validateAscPadFasciPad(state, action)
                break
            case 'SPSI':
                state[soleResNumber].soleResultData[side][key] = value
                validateMinMax(state, action)
                tafelbergCheck(state, action)
                break
            case 'SA15':
            case 'SA25':
            case 'SA1':
            case 'LFW':
            case 'MFW':
                state[soleResNumber].soleResultData[side][key] = value
                validateMinMax(state, action)
                validateConflictsInsoleElements(state, action)
                validateNeskridCoverMaterial(state, action)
                break
            case 'relief':
                state[soleResNumber].soleResultData[side][key] = [...value].sort()
                validateRelief(state, [...value].sort(), soleResNumber, action)
                break
            default:
                state[soleResNumber].soleResultData[side][key] = value
                validateMinMax(state, action)
                validateConflictsInsoleElements(state, action)
                break
            }
            validateAllFields(state, side, soleResNumber)
        },
        validateAscFasci (state, action) {
            validateAscPadFasciPad(state, action)
        },
        soleResultResetByKey (state, action) {
            state.forEach((element, i) => {
                const soleResNumber = i
                const elements = action.payload
                for (const [key, value] of Object.entries(elements)) {
                    state[soleResNumber].soleResultData.left[key] = value
                    state[soleResNumber].soleResultData.right[key] = value
                }
                validateAllFields(state, 'left', soleResNumber)
                validateAllFields(state, 'right', soleResNumber)
            })
        },
        soleResultAccordion (state, action) {
            const soleResNumber = action.payload.soleResNumber
            switch (action.payload.key) {
            case 'accordion':
                state[soleResNumber].soleResultAccordion.accordion = action.payload.value
                if (state[soleResNumber].soleResultAccordion.hasBeenOpened) {
                    validateAllFields(state, 'left', soleResNumber)
                    validateAllFields(state, 'right', soleResNumber)
                } else {
                    state[soleResNumber].soleResultAccordion.hasBeenOpened = true
                }
                break
            case 'visibleElements' :
                state[soleResNumber].soleResultAccordion.visibleElements = action.payload.value
                break
            }
        },
        soleResultSuggestionSimple (state, action) {
            state.forEach((element, i) => {
                const soleResNumber = i
                const elements = action.payload
                for (const [key, value] of Object.entries(elements)) {
                    state[soleResNumber].soleResultSuggestion.left[key] = value
                    state[soleResNumber].soleResultSuggestion.right[key] = value
                }
                validateAllFields(state, 'left', soleResNumber)
                validateAllFields(state, 'right', soleResNumber)
            })
        },
        soleResultDisableSimple (state, action) {
            state.forEach((element, i) => {
                const soleResNumber = i
                const elements = action.payload
                for (const [key, value] of Object.entries(elements)) {
                    state[soleResNumber].soleResultDisabled.left[key] = value
                    state[soleResNumber].soleResultDisabled.right[key] = value
                }
            })
        },
        soleResultSuggestion (state, action) {
            const suggestions = action.payload.suggestions
            const diagnosis = action.payload.diagnosis
            const isStance = action.payload.isStance
            const side = action.payload.side
            const orderType = action.payload.orderType
            state.forEach((element, i) => {
                const soleResNumber = i
                const sole = action.payload.sole[soleResNumber].soleData
                for (const key in suggestions) {
                    if (key === 'name' || key === 'id' || key === 'soleTherapy') continue
                    // if fascipad is not null, set the keysuggestion.
                    if (key === 'fasciPad' || key === 'ascPad') {
                        // skip if block contains 3D PU
                        if (sole.block.key.includes('3D PU') && orderType !== 'fits') continue
                        state[soleResNumber].soleResultSuggestion[side][key] = suggestions[key]
                        state[soleResNumber].soleResultData[side][key] = suggestions[key]
                        continue
                    }

                    // If pelotte is null, empty soleresultsuggestion else test the suggestion to the solesize
                    if (key === 'pelotte') {
                        if (suggestions[key] === null) {
                            state[soleResNumber].soleResultSuggestion[side][key] = ''
                            continue
                        }
                    }

                    if (key === 'MICPosition' || key === 'MICType') {
                        state[soleResNumber].soleResultSuggestion[side][key] = suggestions[key]
                        state[soleResNumber].soleResultData[side][key] = suggestions[key]
                        continue
                    }

                    if (key === 'pelotteType') {
                        if (suggestions[key] !== null) {
                            state[soleResNumber].soleResultSuggestion[side][key] = suggestions[key]

                            let dataValue = 'pelotte'
                            if (suggestions[key] !== 'pelotte') {
                                dataValue = 'pelotteCM' + suggestions[key].match(/\d+/g).join('')
                            }
                            state[soleResNumber].soleResultData[side][key] = dataValue
                        } else if (state[soleResNumber].soleResultSuggestion[side].pelotte > 0) {
                            state[soleResNumber].soleResultSuggestion[side][key] = 'Pelotte'
                            state[soleResNumber].soleResultData[side][key] = 'pelotte'
                        }
                        continue
                    }

                    // If key is asc check ASC, and then place value. otherwise put suggestion value in the field.
                    if (suggestions[key] != null) {
                        if (key === 'ASC') {
                            // if fits make the suggestion diffrent than insoles.
                            if (orderType === 'fits') {
                                // if diagnosis contains fascipad, then make ASC suggestion 9, else make it 6.
                                if (suggestions.fasciPad === 'true') {
                                    state[soleResNumber].soleResultSuggestion[side].ASC = 9
                                } else {
                                    state[soleResNumber].soleResultSuggestion[side].ASC = 6
                                }
                            } else {
                                state[soleResNumber].soleResultSuggestion[side].ASC = checkASC(suggestions, diagnosis, isStance, side)
                            }
                            continue
                        }
                    }
                    state[soleResNumber].soleResultSuggestion[side][key] = suggestions[key] || ''
                    if (suggestions[key] === '' || suggestions[key] === null) {
                        state[soleResNumber].soleResultErrors[side][key] = ''
                    }
                }
                action.payload.soleResNumber = soleResNumber
                tafelbergCheck(state, action)
                validateAllFields(state, side, soleResNumber)
            })
        },
        soleResultSuggestionByKey (state, action) {
            const key = action.payload.key
            const value = action.payload.value
            const side = action.payload.side
            const soleResNumber = action.payload.soleResNumber
            state[soleResNumber].soleResultSuggestion[side][key] = value
            validateAllFields(state, side, soleResNumber)
        },
        soleResultDisableByKey (state, action) {
            const key = action.payload.key
            const value = action.payload.value
            const side = action.payload.side
            const soleResNumber = action.payload.soleResNumber
            state[soleResNumber].soleResultDisabled[side][key] = value
            validateAllFields(state, side, soleResNumber)
        },
        tafelberg (state, action) {
            tafelbergCheck(state, action)
        },
        copyResult (state, action) {
            const soleResNumber = action.payload.soleResNumber
            if (action.payload.side === 'left') {
                state[soleResNumber].soleResultData = state[0].soleResultData
                state[soleResNumber].soleResultErrors = state[0].soleResultErrors
                state[soleResNumber].soleResultAccordion.left.validate = state[0].soleResultAccordion.left.validate
                state[soleResNumber].soleResultAccordion.visibleElements = state[0].soleResultAccordion.visibleElements
                tafelbergCheck(state, action)
            } else {
                state[soleResNumber].soleResultAccordion.right.validate = state[0].soleResultAccordion.right.validate
                state[soleResNumber].soleResultAccordion.visibleElements = state[0].soleResultAccordion.visibleElements
                tafelbergCheck(state, action)
            }
        },
        copyLeft (state, action) {
            const soleResNumber = action.payload.soleResNumber
            state[soleResNumber].soleResultData.right = state[soleResNumber].soleResultData.left
            state[soleResNumber].soleResultErrors.right = state[soleResNumber].soleResultErrors.left
            state[soleResNumber].soleResultAccordion.right.validate = state[soleResNumber].soleResultAccordion.left.validate
            tafelbergCheck(state, action)
        },
        closeSoleResult (state, action) {
            const nrOfSoles = action.payload.diagnosis.numberOfSoles
            for (let index = 0; index < nrOfSoles; index++) {
                const soleResNumber = index

                state[soleResNumber].soleResultAccordion.accordion = false
                state[soleResNumber].soleResultAccordion.hasBeenOpened = true
                action.payload.soleResNumber = soleResNumber
                action.payload.side = 'left'

                const payloadLeft = {
                    orderType: action.payload.orderType,
                    side: 'left',
                    soleResNumber,
                    key: 'ASC',
                    value: state[soleResNumber].soleResultData.left.ASC,
                    sole: action.payload.orderType === 'insole' ? action.payload.sole : undefined,
                    fits: action.payload.orderType === 'fits' ? action.payload.fits : undefined
                }
                validateFitsAsc(state, { payload: payloadLeft })
                tafelbergCheck(state, action)
                validatePelotteSuggestion(state, action)

                payloadLeft.key = 'CV'
                payloadLeft.value = state[soleResNumber].soleResultData.left.CV
                // validate CV minMax
                validateMinMax(state, { payload: payloadLeft })
                validateAllFields(state, 'left', soleResNumber)

                // check right aswell
                action.payload.side = 'right'
                const payloadRight = {
                    orderType: action.payload.orderType,
                    side: 'right',
                    soleResNumber,
                    key: 'ASC',
                    value: state[soleResNumber].soleResultData.left.ASC,
                    sole: action.payload.orderType === 'insole' ? action.payload.sole : undefined,
                    fits: action.payload.orderType === 'fits' ? action.payload.fits : undefined
                }
                validateFitsAsc(state, { payload: payloadRight })
                tafelbergCheck(state, action)
                validatePelotteSuggestion(state, action)

                payloadRight.key = 'CV'
                payloadLeft.value = state[soleResNumber].soleResultData.right.CV
                // validate CV minMax
                validateMinMax(state, { payload: payloadRight })
                validateAllFields(state, 'right', soleResNumber)
            }
        },
        importSoleResult (state, action) {
            importSoleResultFunc(state, action)
        },
        importSoleResultSuggestion (state, action) {
            importSoleResultSuggestionFunc(state, action)
        },
        resetSoleResult (state, action) {
            state.forEach((element, i) => {
                const soleResNumber = i
                state[soleResNumber].soleResultData = action.payload.soleResultData
                state[soleResNumber].soleResultErrors = action.payload.soleResultErrors
                state[soleResNumber].soleResultSuggestion = action.payload.soleResultSuggestion
            })
        },
        updateFasciASC (state, action) {
            const newBlock = action.payload.newBlock || ''
            const oldBlock = action.payload.oldBlock
            const diagnosis = action.payload.diagnosis
            const soleResNumber = action.payload.soleResNumber

            if (newBlock.key.includes('3D PU')) {
                // fasci data
                state[soleResNumber].soleResultData.left.fasciPad = 'false'
                state[soleResNumber].soleResultData.right.fasciPad = 'false'
                state[soleResNumber].soleResultSuggestion.left.fasciPad = 'false'
                state[soleResNumber].soleResultSuggestion.right.fasciPad = 'false'

                // asc data
                state[soleResNumber].soleResultData.left.ascPad = 'false'
                state[soleResNumber].soleResultData.right.ascPad = 'false'
                state[soleResNumber].soleResultSuggestion.left.ascPad = 'false'
                state[soleResNumber].soleResultSuggestion.right.ascPad = 'false'
            } else if (oldBlock.key.includes('3D PU')) {
                // fasci data
                state[soleResNumber].soleResultData.left.fasciPad = diagnosis.fasciPad !== undefined ? diagnosis.fasciPad : ''
                state[soleResNumber].soleResultData.right.fasciPad = diagnosis.fasciPad !== undefined ? diagnosis.fasciPad : ''
                state[soleResNumber].soleResultSuggestion.left.fasciPad = diagnosis.fasciPad !== undefined ? diagnosis.fasciPad : ''
                state[soleResNumber].soleResultSuggestion.right.fasciPad = diagnosis.fasciPad !== undefined ? diagnosis.fasciPad : ''

                // asc data
                state[soleResNumber].soleResultData.left.ascPad = diagnosis.ascPad !== undefined ? diagnosis.ascPad : ''
                state[soleResNumber].soleResultData.right.ascPad = diagnosis.ascPad !== undefined ? diagnosis.ascPad : ''
                state[soleResNumber].soleResultSuggestion.left.ascPad = diagnosis.ascPad !== undefined ? diagnosis.ascPad : ''
                state[soleResNumber].soleResultSuggestion.right.ascPad = diagnosis.ascPad !== undefined ? diagnosis.ascPad : ''
            }
        }
    }
})

/**
 * Check ASC values for the highest value. checks between stanceSuggestion and diagnosis
 *
 * @param {String} suggestion - ASC suggestion.
 * @param {Objectg} diagnosis - Given diagnosis
 * @param {Boolean} isStance - is true when theres a stance difference between
 * @param {String} side - left or right.
 * @returns {Number} - highets value, when theres a stance difference, or diagnosis value is applied.
 */
function checkASC (suggestion, diagnosis, isStance, side) {
    const asc = suggestion.ASC === '' ? '' : parseInt(suggestion.ASC)
    const diagnosASC = diagnosis.diagnosis.ASC || ''
    const stanceASC = side === 'left' ? diagnosis.leftStanceDifference.ASC || '' : diagnosis.rightStanceDifference.ASC || ''

    const highestValue = isStance ? Math.max(diagnosASC, asc) : Math.max(stanceASC, asc)
    if (highestValue === 0) {
        return ''
    } else {
        return highestValue
    }
}

/**
 * Set sole data function
 *
 * @param {Obejct} state - current redux state.
 * @param {Object} action - action containing data.
 * @return {Object} the full state of soleResult is returned
 */
export function importSoleResultFunc (state, action) {
    state.forEach((element, i) => {
        const soleResNumber = i
        if (action.payload[soleResNumber] !== undefined) {
            for (const key in action.payload[soleResNumber].left) {
                const newKey = key.toUpperCase()
                if (key === 'pelotte' || key === 'tafelberg' || key === 'fasciPad' || key === 'ascPad' || key === 'relief' || key === 'MICPosition' || key === 'MICType' || key === 'pelotteType') {
                    state[soleResNumber].soleResultData.left[key] = action.payload[soleResNumber].left[key] !== null ? action.payload[soleResNumber].left[key] : ''
                    state[soleResNumber].soleResultData.right[key] = action.payload[soleResNumber].right[key] !== null ? action.payload[soleResNumber].right[key] : ''
                } else {
                    state[soleResNumber].soleResultData.left[newKey] = action.payload[soleResNumber].left[key] !== null ? action.payload[soleResNumber].left[key] : ''
                    state[soleResNumber].soleResultData.right[newKey] = action.payload[soleResNumber].right[key] !== null ? action.payload[soleResNumber].right[key] : ''
                }
            }
        }
    })
    return state
}
/**
 * Set sole data function
 *
 * @param {Obejct} state - current redux state.
 * @param {Object} action - action containing data.
 * @return {Object} the full state of soleResult is returned
 */
export function importSoleResultSuggestionFunc (state, action) {
    state.forEach((element, i) => {
        const soleResNumber = i
        if (action.payload[soleResNumber] !== undefined) {
            for (const key in action.payload[soleResNumber].left) {
                const newKey = key.toUpperCase()
                if (key === 'pelotte' || key === 'tafelberg' || key === 'fasciPad' || key === 'ascPad' || key === 'pelotteType') {
                    state[soleResNumber].soleResultSuggestion.left[key] = `${action.payload[soleResNumber].left[key]}`
                    state[soleResNumber].soleResultSuggestion.right[key] = `${action.payload[soleResNumber].right[key]}`
                } else {
                    state[soleResNumber].soleResultSuggestion.left[newKey] = `${action.payload[soleResNumber].left[key]}`
                    state[soleResNumber].soleResultSuggestion.right[newKey] = `${action.payload[soleResNumber].right[key]}`
                }
            }
        }
    })
    return state
}

export const { soleResultData, soleResultAccordion, validateAscFasci, setStanceNote, closeSoleResult, tafelberg, soleResultSuggestion, soleResultDisableByKey, soleResultDisableSimple, soleResultSuggestionByKey, soleResultSuggestionSimple, copyResult, copyLeft, importSoleResultSuggestion, importSoleResult, resetSoleResult, updateFasciASC, soleResultResetByKey } = soleResultSlice.actions
export default soleResultSlice.reducer
