import moment from 'moment'
import { combineItems, getPrefix } from './util'

/** @import { SlimFormat, FitsSpecification, Diagnosis, SoleAdditions, SoleSpecification, SoleResult, SoleAddition, SoleResultSuggestion } from './slimfileformat/format_1_8_3' */
/** @import { ReduxState, SoleAdditionRedux, SoleResultRedux, SoleSpecificationRedux } from './slimfileformat/reduxState' */


/**
 * Create a JSON export for rhino modeling.
 *
 * @param {ReduxState} state - the full state of the application.
 */
function rhinoExport(state) {
    const patient = state.general.patientData
    const orderType = state.general.orderType
    const diagnosis = state.diagnosis.diagnosisData
    const soleSpec = state.soleSpecification
    const fitsSpec = state.fitsSpecification
    const soleRes = state.soleResult
    const soleAddition = state.soleAddition
    const patientName = `${patient.lastName}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

    let rhinoExport = {
        sendDate: moment().toISOString(),
        fitsType: 'fitsCustomResult',
        general: {
            patient: {
                name: patientName,
                sex: patient.gender,
                birthdate: moment(patient.birthdate).toISOString(),
                id: getPrefix(state.other.backend) + parseInt(patient.number.replace(/\D/g, '')),

            },
            emergencyOrder: patient.emergencyOrder,
            postponeOrder: patient.postponeOrder,
            therapist: patient.therapist.name,
            fittingLocation: updateLocationNames(patient.fittingLocation.title, state.other.backend.key),
            receiveLocation: updateLocationNames(patient.receiveLocation.title, state.other.backend.key),
            completePair: diagnosis.completePair
        },
        fitsResult: modelFitsResultRhino(fitsSpec, soleRes, diagnosis),
        soleResult: modelSoleResultRhino(soleSpec, soleRes, diagnosis, soleAddition)
    }

    if (orderType === 'fits') {
        // @ts-ignore
        delete rhinoExport.soleResult
        delete rhinoExport.general.emergencyOrder
        delete rhinoExport.general.completePair

        // if the right side is not modeled, remove it ...
        if (diagnosis.symmetrical && !fitsSpec[0].fitsData.displayRightSole) {
            // @ts-ignore
            delete rhinoExport.fitsResult.right
        }
    } else {
        // @ts-ignore
        delete rhinoExport.fitsType
        // @ts-ignore
        delete rhinoExport.fitsResult

        // if the right side is not modeled, remove it ...
        if (diagnosis.symmetrical && !soleSpec[0].soleData.displayRightSole) {
            // @ts-ignore
            delete rhinoExport.soleResult.right
        }
    }

    return rhinoExport
}

/**
 *
 * @param {*} fitsSpec
 * @param {*} soleRes
 * @param {*} diagnosis
 * @returns
 */
function modelFitsResultRhino(fitsSpec, soleRes, diagnosis) {
    const soleResult = []
    for (let i = 0; i < diagnosis.numberOfSoles; i++) {
        soleResult.push({
            left: {
                footSize: parseFloat(fitsSpec[i].fitsData.footSizeLeft),
                elements: modelSoleResultElements(soleRes[i].soleResultData.left, soleRes[i].soleResultSuggestion.left, fitsSpec[i].fitsData, true)
            },
            right: {
                footSize: parseFloat(fitsSpec[i].fitsData.footSizeRight),
                elements: modelSoleResultElements(diagnosis.symmetrical ? soleRes[i].soleResultData.left : soleRes[i].soleResultData.right, soleRes[i].soleResultSuggestion.right, fitsSpec[i].fitsData, true)
            },
            fitsSpecification: modelFitsSpecifications(fitsSpec[i].fitsData)
        })
    }

    return soleResult
}

/**
 * Filter values with a for loop, to get only the values entered OR values with a suggestion.
 *
 * @param {SoleResult} soleResInput - sole result inxwput values.
 * @param {SoleResultSuggestion} soleResSug - sole result suggesiton values.
 * @param {SoleSpecification} spec 
 * @param {boolean} isFits 
 * @return {Object} - filtered soleresult elements.
 */
function modelSoleResultElements(soleResInput, soleResSug, spec, isFits) {
    const model = []
    const soleType = spec.soleType === 'podozool' ? 'podo' : 'ortho'
    for (const [key, value] of Object.entries(soleResInput)) {
        // skip if tafelberg, heellift and mictype.
        if (key === 'tafelberg' || key === 'externalHeelLift' || key === 'MICType' || key === 'MICPosition' || key === 'pelotteType') continue

        let suggestedKey = key

        if (key === 'MIC') {
            suggestedKey = `${soleType}${soleResInput.MICType}${soleResInput.MICPosition === 'TMT - 1' ? 'TMT-1' : ''}`
        } else if (key === 'pelotte') {
            if (soleResInput.pelotteType !== 'pelotte' && soleResInput.pelotteType !== '' && soleResInput.pelotteType !== null) {
                suggestedKey = 'pelotteCM' + soleResInput.pelotteType.match(/\d+/g).join('')
            }
        }

        // if fasciPad or ascPad check if the string value is the same as 'true'.
        if (key === 'fasciPad' || key === 'ascPad') {
            model.push({
                name: suggestedKey,
                value: value === 'true'
            })
            continue
        }

        if (isFits && key === 'ASC') {
            let correctValue = parseInt(value) || null
            const tafelberg = parseFloat(soleResInput.tafelberg) || 0
            const extHeel = parseFloat(soleResInput.externalHeelLift) || 0

            if (value === '' || value === null) {
                const suggestionValue = soleResSug[key]
                if (suggestionValue !== '') {
                    // not empty, use it
                    correctValue = parseInt(suggestionValue) || 0
                }
            }

            model.push({
                name: suggestedKey,
                value: calculateHeights(correctValue, tafelberg, extHeel).asc
            })

            model.push({
                name: 'externalHeelLift',
                value: calculateHeights(correctValue, tafelberg, extHeel).extHeel
            })
            continue
        }

        // if there is not an value entered check for an suggestion.
        if (value === '' || value === null) {
            // check the suggestion value
            const suggestionValue = soleResSug[key]
            if (suggestionValue !== '') {
                // not empty, use it
                model.push({
                    name: suggestedKey,
                    value: parseInt(suggestionValue) || 0
                })
            }
            // empty, skip it
            continue
        }

        if (key === 'relief') {
            const combinedItems = combineItems(value)
            combinedItems.forEach(element => {
                model.push({
                    name: `relief${element}`,
                    value: 0 - soleResInput.SA15
                })
            })
        } else {
            model.push({
                name: suggestedKey,
                value: parseInt(value)
            })
        }
    }
    return model
}

/**
 * get json export of fitsspecifications
 *
 * @param {FitsSpecification} spec - sole specification
 * @returns {Object} with solespecification.
 */
function modelFitsSpecifications(spec) {
    let patternName = ''

    if (!spec.model.displayValue) {
        patternName = ''
    } else if (spec.model.displayValue.includes('Teenslipper')) {
        patternName = 'Teenmodel'
    } else {
        patternName = 'Bandenmodel'
    }
    const fitsSpecRhino = {
        model: spec.model,
        patternName,
        block: generateBlock(spec.block).name,
        millingSize: generateBlock(spec.block).height,
        upperMaterial: spec.upperMaterial,
        coverMaterial: spec.coverMaterial,
        outsole: spec.outsole,
        finishingShape: spec.finishingShape,
        upperPosition: spec.upperPosition,
        soleSizeLeft: parseFloat(spec.soleSizeLeft),
        soleSizeRight: parseFloat(spec.soleSizeRight),
        leftSoleWidth: parseFloat(spec.leftSoleWidth),
        leftSoleWidthMessage: spec.leftSoleWidthMessage,
        rightSoleWidth: parseFloat(spec.rightSoleWidth),
        rightSoleWidthMessage: spec.rightSoleWidthMessage,
        hypoallergenicGlue: spec.hypoallergenicGlue,
        extraElement: (spec.extraElement.includes('30sh 3mm EVA - zwart') ? '30sh 3mm EVA - zwart' : false)
    }

    return fitsSpecRhino
}

/**
 * Generate a block object with a string
 *
 * @param {String} block - block name
 * @return {{name: string, height: string}} block object with height and name
 */
function generateBlock(block) {
    const objBlock = {}
    if (block.includes('38mm hoog')) {
        objBlock.height = '38 mm'
        objBlock.name = block.replace(' - 38mm hoog', '')
    } else if (block.includes(' - 38mm -')) {
        objBlock.height = '38 mm'
        objBlock.name = block.replace(' - 38mm', '')
    } else if (block.includes(' - 30mm -')) {
        objBlock.height = '30 mm'
        objBlock.name = block.replace(' - 30mm', '')
    } else {
        objBlock.height = '30 mm'
        objBlock.name = block
    }

    return objBlock
}

/**
 * Models the sole result for a Rhino diagnosis, generating structured data for each sole.
 *
 * @param {SoleSpecificationRedux[]} soleSpec - Array of sole specifications, each containing data for the sole.
 * @param {SoleResultRedux[]} soleRes - Array of sole result data and suggestions.
 * @param {Diagnosis} diagnosis - Diagnosis object, containing details such as `numberOfSoles`, `completePair`, and `symmetrical`.
 * @param {SoleAdditionRedux} soleAdd - Additional specifications or details for the sole, including an `addition` property.
 * @returns {Object} An array of sole result objects, each containing left and right foot details, sole specifications, and additions.
 */
function modelSoleResultRhino(soleSpec, soleRes, diagnosis, soleAdd) {
    const soleResult = []
    for (let i = 0; i < parseInt(diagnosis.numberOfSoles); i++) {
        soleResult.push({
            left: diagnosis.completePair === 'right'
                ? {}
                : {
                    footSize: parseFloat(soleSpec[i].soleData.footSizeLeft),
                    elements: modelSoleResultElements(soleRes[i].soleResultData.left, soleRes[i].soleResultSuggestion.left, soleSpec[i].soleData, false)
                },
            right: diagnosis.completePair === 'left'
                ? {}
                : {
                    footSize: parseFloat(soleSpec[i].soleData.footSizeRight),
                    elements: modelSoleResultElements(diagnosis.symmetrical ? soleRes[i].soleResultData.left : soleRes[i].soleResultData.right, soleRes[i].soleResultSuggestion.right, soleSpec[i].soleData, false)
                },
            soleSpecification: modelSoleSpecifications(soleSpec[i].soleData),
            soleAdditions: modelSoleAdditions(soleAdd.additionData.addition, i)
        })
    }

    return soleResult
}

/**
 *
 * @param {SoleAddition[]} soleAdd
 * @param {number} number
 */
function modelSoleAdditions(soleAdd, number) {
    const soleAddArr = []
    soleAdd.forEach((element) => {
        if (element.forSoles.indexOf(number) > -1) {
            soleAddArr.push({ name: element.name, location: element.location })
        }
    })
    return soleAddArr
}

/**
 * get json export of solespecifications
 *
 * @param {SoleSpecification} spec - sole specification
 * @returns {Object} with solespecification.
 */
function modelSoleSpecifications(spec) {
    return {
        millingSize: parseInt(spec.millingSize),
        block: generateBlock(spec.block.key).name,
        groundSole: spec.groundSole.name,
        soleSizeLeft: parseFloat(spec.soleSizeLeft),
        soleSizeRight: parseFloat(spec.soleSizeRight),
        broadenedFront: spec.broadenedFront === '' || spec.broadenedFront === null ? 0 : parseInt(spec.broadenedFront),
        coverMaterial: spec.coverMaterial,
        coverLevel: spec.coverMaterial === '-' ? '-' : spec.coverLevel,
        totalLeftSoleThickness: parseFloat(spec.totalLeftSoleThickness),
        totalRightSoleThickness: parseFloat(spec.totalRightSoleThickness),
        soleCompleteness: spec.soleCompleteness,
        narrowGrinding: false,
        hypoallergenicGlue: spec.hypoallergenicGlue
    }
}

/**
 *
 * @param {*} asc
 */
function calculateHeights(ASC, tafelberg, externalHeelLift) {
    const minASC = 6 // minimale ASC hoogte voor FITS custom
    const minExtHeel = 2 // minimale waarde voor de externe heel lift die in PLTLife ingevuld wordt
    let extHeel
    let asc

    if (tafelberg > 0) {
        extHeel = tafelberg
        asc = ASC - extHeel

        if (externalHeelLift < minExtHeel) {
            asc = ASC - (externalHeelLift - minExtHeel)
            extHeel = minExtHeel
        }

        if (ASC < minASC) {
            extHeel = tafelberg + (minASC - ASC)
            asc = minASC
        }
    } else {
        asc = ASC
        extHeel = externalHeelLift
    }

    return { asc, extHeel }
}

/**
 * 
 */
function updateLocationNames(locName, backend) {
    let result = locName
    if (backend === 'Voetmax') {
        if (locName === 'Deventer Medisch Centrum Stadspoort') result = 'Deventer Medisch Centrum'
        if (locName === 'Dalfsen Prins Hendrikstraat ') result = 'Dalfsen Prins Hendrikstr'
        if (locName === 'Kampen Gezondheidscentrum de Hanze') result = 'Kampen GHC de Hanze'
        if (locName === 'Poli Apeldoorn - Gelre Sport') result = 'Poli Apeldoorn - Gelre'
        if (locName === '(.Voetmax) Sturen pt via Zoollogist') return result

        return 'Voetmax - ' + result
    } else {
        if (locName === 'Nijverdal Medisch Centrum Nijkerkendijk') result = 'Nijverdal MC Nijkerkendijk'
        if (locName === 'Nijverdal Medisch Centrum Kruidenwijk') result = 'Nijverdal MC Kruidenwijk'
        if (locName === 'Enschede SL Louwesstraat') result = 'Enschede S.L. Louwesstraat'
        if (locName === 'Poli Gouda - Grone Hart Ziekenhuis') result = 'Gouda Bleulandweg'
        if (locName === '(.VCW) Sturen pt via Zoollogistiek') return result

        return 'VCW ' + result
    }
}

export { rhinoExport }
