import { RecPartial } from './types';

/**
 * Performs a deep copy on the object.
 * This preserves any non-object types
 *
 * @param object the object to copy
 * @returns the copy
 * @template T
 */
function deepCopy<T> (object: T | null | undefined): T | null | undefined {
    // Handle null, undefined, boolean, number, bigint, string and symbol values
    // undefined is not an instance of object, while null is (great!)
    if (object === null || !(object instanceof Object) ) { 
        return object 
    }

    if (Array.isArray(object)) {
        const out: unknown[] = []
        for (const value of object) {
            out.push(deepCopy(value))
        }
        return out as T;
    } else {
        const out: Record<string, unknown> = {}
        for (const [key, value] of Object.entries(object)) {
            out[key] = deepCopy(value)
        }
        return out as T
    }
}

/**
 * Get prefix of environment
 *
 * @param backend - other.backend variable
 * @returns the given Prefix for that environment
 */
function getPrefix (backend: object) {
    if (!('key' in backend)) {
        return ''
    }

    switch (backend.key) {
    case 'Penders':
        return 'P'
    case 'Voetmax':
        return 'VM'
    case 'Kievit Orthopedie':
        return ''
    default:
        return ''
    }
}

/**
 * Retrieves a location prefix based on the backend's key property.
 *
 * @param {object} backend - The backend object containing a key property.
 * @returns {string} The location prefix associated with the backend's key, or an empty string if no key is present or no match is found.
 */
function getLocationPrefix (backend: object){
    if (!('key' in backend)) {
        return ''
    }

    switch (backend.key) {
    case 'Voetencentrum Wender':
        return 'VCW - '
    case 'Voetmax':
        return 'Voetmax -'
    default:
        return ''
    }
}

enum PelotteCombinations {
    CM1 = 'CM1',
    CM2 = 'CM2',
    CM3 = 'CM3',
    CM4 = 'CM4',
    CM5 = 'CM5',
    CM23 = 'CM23',
    CM34 = 'CM34',
    CM45 = 'CM45',
    CM234 = 'CM234',
    CM345 = 'CM345'
}

/**
 *
 * @param arr -
 * @returns
 */
function combineItems (arr: string[]): PelotteCombinations[] {
    const combinations: Record<string, PelotteCombinations> = {
        CM1: PelotteCombinations.CM1,
        CM2: PelotteCombinations.CM2,
        CM3: PelotteCombinations.CM3,
        CM4: PelotteCombinations.CM4,
        CM5: PelotteCombinations.CM5,
        CM2CM3: PelotteCombinations.CM23,
        CM3CM4: PelotteCombinations.CM34,
        CM4CM5: PelotteCombinations.CM45,
        CM2CM3CM4: PelotteCombinations.CM234,
        CM3CM4CM5: PelotteCombinations.CM345
    }

    const result: PelotteCombinations[] = []
    const availableItems = [...arr]

    for (let i = 0; i < availableItems.length; i++) {
        const currentItem = availableItems[i]
        let longestCombination = currentItem // Initialize with the current item

        for (let j = i + 1; j < availableItems.length; j++) {
            const combinedItem = longestCombination + availableItems[j]
            if (combinations[combinedItem]) {
                longestCombination = combinedItem // Update longest combination found
                i = j // Skip checking shorter combinations
            }
        }

        if (!result.find(item => item.includes(currentItem))) {
            result.push(combinations[longestCombination])
        }
    }

    return result
}

/**
 * Convert the UK size to the EU size
 *
 * @param ukSize - the UK size
 * @return the EU size
 */
function ukToEu (ukSize: number) {
    const initialSize = parseInt(((1.27 * (ukSize + 23)) + 2).toFixed(1))
    const euSize = Math.round(initialSize * 2) / 2
    return euSize
}

/**
 * Convert the EU size to the UK size
 *
 * @param  euSize - the EU size
 * @return - the UK size
 */
function euToUk (euSize: number): number {
    const initialSize = parseInt((((euSize - 2) / 1.27) - 23).toFixed(1))  // (1.27 * ukSize) + 32.51
    const ukSize = Math.round(initialSize * 2) / 2
    return ukSize
}

/**
 * Deep copy values from to the state, falling back to values from the {@link initialState} if they are undefined
 * in the {@link newState}.
 * 
 * @param state Object to copy the values into
 * @param newState The new state to copy into the {@link state}
 * @param initialState The initial state to fall back to
 */
function copyValuesIntoState<State> (
    state: State,
    newState: RecPartial<State>,
    initialState: State
) {
    for (const key in state) {
        if (state[key] instanceof Object) {
            copyValuesIntoState(state[key], newState[key] ?? {}, initialState[key])
        } else {
            state[key] = key in newState
                ? newState[key] as State[typeof key]
                : initialState[key]
        }
    }
}
/**
 * Converts a base64-encoded string to a {@link Blob}
 * @param value Base64-encoded string
 * @param type Mime type of the data
 */
function base64ToBlob (value: string, type: string): Blob {
    const sole = atob(value)
    const binaryString = sole
    const len = binaryString.length
    const bytes = new Uint8Array(len)

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
    }

    return new Blob([bytes], { type })
}

/**
 * Checks if a key is in the object. Using this function will make TypeScript aware
 * this key exist in the object and will stop complaining about it
 *
 * @param key The key to check for
 * @param obj The object to check on
 * @returns True if the object contains the given key
 * @example
 * let foo: object = {"bar": true}
 * foo.bar = false // typescript will complain
 * if (isKeyObject(object, "bar")) {
 *     // typescript will not complain
 *     foo.bar = false
 * }
 *
 */
function isKeyOfObject<T extends object>(
    obj: T,
    key: PropertyKey
): key is keyof T {
    return key in obj;
}

/**
 * 
 * @param rsp response
 */
function setVMLocations(rsp: {id: number, title:string}[]){
    const result = []
    for (let index = 0; index < rsp.length; index++) {
        if(rsp[index].title.includes('Arnhem Meander')) continue
        if(rsp[index].title.includes('Doetichem de Bolster')) continue
        if(rsp[index].title.includes('Enschede MST')) continue
        if(rsp[index].title.includes('Leiden LUMC')) continue
        if(rsp[index].title.includes('Leiden Novareva')) continue
        if(rsp[index].title.includes('Leiden TSI')) continue
        if(rsp[index].title.includes('VCW ')) continue

        result.push(rsp[index])
    }

    return result
}


export { deepCopy, ukToEu, euToUk, getPrefix, getLocationPrefix, combineItems, base64ToBlob, copyValuesIntoState, isKeyOfObject, setVMLocations }