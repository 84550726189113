import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

const importSuggestionSlice = createSlice({
    name: 'importSuggestion',
    initialState,
    reducers: {
        setSuggestion (state, action) {
            state.soleSpecification = action.payload.soleSpecification
            state.fitsSpecification = action.payload.fitsSpecification
        }
    }
})

export const { setSuggestion } = importSuggestionSlice.actions
export default importSuggestionSlice.reducer
