export const modelChoices = new Map()
modelChoices.set('male', [
    { displayValue: 'Lissabon (Teenslipper)', key: 'teenslipper' },
    { displayValue: 'Costa (Teenslipper)', key: 'teenslipper_vegan' },
    { displayValue: 'Rio (Tweeband)', key: 'tweeband' },
    { displayValue: 'Vale (Tweeband met hiel)', key: 'tweeband_hiel' },
    { displayValue: 'Lima (Drieband)', key: 'drieband' },
    { displayValue: 'Montanha (Drieband met hiel)', key: 'drieband_hiel' },
    { displayValue: 'Algarve (E\xE9nband)', key: 'eenband' },
    { displayValue: 'Setubal (Drieband)', key: 'setubal' }
])
modelChoices.set('female', [
    { displayValue: 'Porto (Teenslipper)', key: 'teenslipper' },
    { displayValue: 'Amora (Teenslipper)', key: 'teenslipper_vegan' },
    { displayValue: 'Vila Nova (Tweeband)', key: 'tweeband' },
    { displayValue: 'Douro (Tweeband met hiel)', key: 'tweeband_hiel' },
    { displayValue: 'Maia (Drieband)', key: 'drieband' },
    { displayValue: 'Faro (Drieband met hiel)', key: 'drieband_hiel' },
    { displayValue: 'Setubal (Drieband)', key: 'setubal' }
])

export const outsoleChoices = [ // loopzool
    'EVA 10mm zwart grof',
    'EVA 10mm bruin grof',
    'EVA 10mm zwart fijn',
    'EVA 10mm bruin fijn',
    'Creplina beige',
    'Creplina blauw',
    'Creplina wit',
    'Creplina zwart'
]

export const blockChoices = [ // blokkeuze
    'EVA 50sh - 30mm - zwart',
    'EVA 50sh - 30mm - kurk',
    'EVA 50sh - 38mm - zwart',
    'EVA 50sh - 38mm - kurk'
]

export const finishingShapeOptions = [
    'Volledig afgewerkt',
    'Los bijleveren',
    'Afdek tot RCTB vastgeplakt'
]

export const upperPositionOptions = [
    'Standaard',
    'Hoge wreef',
    'Lage wreef'
]

export const coverMaterial = [
    'Flexline zwart',
    'Gladleder beige',
    'Gladleder blauw',
    'Gladleder bruin',
    'Gladleder cognac',
    'Gladleder grijs',
    'Gladleder zwart',
    'Su\xE8de beige',
    'Su\xE8de blauw',
    'Su\xE8de bruin',
    'Su\xE8de cognac',
    'Su\xE8de grijs',
    'Su\xE8de zwart'
]

export const upperMaterialOptions = new Map()
upperMaterialOptions.set('male', new Map())
upperMaterialOptions.set('female', new Map())

upperMaterialOptions.get('male').set('teenslipper_vegan', [
    'Bruin suede',
    'Naturex cognac leer',
    'Naturex donkerblauw leer',
    'Naturex groen leer',
    'Naturex zwart leer'
]).set('teenslipper', [
    'Berg groen leer',
    'Cognac leer',
    'Zwart leer'
]).set('tweeband', [
    'Naturex zwart leer',
    'Naturex cognac leer',
    'Naturex donkerblauw leer',
    'Mocca suede',
    'Bruin suede',
    'Naturex rood leer',
    'Berg groen leer',
    'Zwart leer',
    'Cognac leer'
]).set('tweeband_hiel', [
    'Naturex zwart leer',
    'Naturex cognac leer',
    'Naturex donkerblauw leer',
    'Bruin suede',
    'Mocca suede',
    'Zwart leer',
    'Gommatto leer',
    'Cognac leer'
]).set('drieband', [
    'Naturex zwart leer',
    'Naturex cognac leer',
    'Naturex donkerblauw leer',
    'Bruin suede',
    'Zwart leer',
    'Donkerbruin leer',
    'Cognac leer'
]).set('drieband_hiel', [
    'Naturex zwart leer',
    'Naturex cognac leer',
    'Naturex donkerblauw leer',
    'Bruin suede',
    'Zwart leer',
    'Donkerbruin leer',
    'Cognac leer'
]).set('eenband', [
    'Naturex zwart leer',
    'Naturex cognac leer',
    'Naturex donkerblauw leer',
    'Bruin suede',
    'Mocca suede',
    'Naturex groen leer',
    'Naturex rood leer',
    'Zwart leer',
    'Donkerbruin leer',
    'Camel leer'
]).set('setubal', [
    'Naturex zwart leer',
    'Bruin suede'
])

upperMaterialOptions.get('female').set('teenslipper_vegan', [
    'Magic grijs leer',
    'Magic brons leer',
    'Laser vibe wit leer',
    'Botega blauw leer',
    'Hidro orchidee nubuck',
    'Hidro peach nubuck',
    'Hidro groen nubuck',
    'Zwart vegan leer',
    'Zwart bloem vegan leer',
    'Blauw vegan leer',
    'Blauw bloem vegan leer'
]).set('teenslipper', [
    'Fantasie wit leer',
    'Laser vibe wit leer',
    'Glans nacht blauw leer',
    'Magic grijs leer',
    'Magic brons leer',
    'Fantasie panter leer',
    'Hidro orchidee nubuck',
    'Hidro peach nubuck',
    'Hidro groen nubuck',
    'Hidro marigold nubuck',
    'Forest leaf leer',
    'Fantasie circle leer',
    'Goud leer',
    'Brons leer',
    'Zwart glitter leer',
    'Zwart leer',
    'Zwart bloem vegan leer',
    'Blauw bloem vegan leer',
    'Blauw glans leer',
    'Beige snake nubuck',
    'Cognac leer',
    'Zwart glans leer',
    'Zwart panther velvet',
    'Koraal fantasy leer',
    'Blauw fantasy leer',
    'Camel leer',
    'Antraciet leer',
    'Blauw leer',
    'Groen leer',
    'Fushsia leer',
    'Zalm leer',
    'Zilver print leer',
    'Antraciet snake leer'
]).set('tweeband', [
    'Taupe suede',
    'Montana cognac leather',
    'Hidro orchidee nubuck',
    'Hidro peach nubuck',
    'Hidro groen nubuck',
    'Hidro marigold nubuck',
    'Magic grijs leer',
    'Magic brons leer',
    'Fantasie wit leer',
    'Fantasie panter leer',
    'Zwart leer',
    'Brons leer',
    'Zwart linnen leer',
    'Beige snake nubuck',
    'Antraciet leer',
    'Blauw leer',
    'Forest leaf leather',
    'Antraciet snake leer',
    'Zwart panter velvet',
    'Koraal fantasy leer',
    'Blauw fantasy leer',
    'Zilver print leer',
    'Groen leer'
]).set('tweeband_hiel', [
    'Taupe suede',
    'Botega blauw leer',
    'Montana cognac leather',
    'Hidro peach nubuck',
    'Hidro oker rood nubuck',
    'Hidro jeans nubuck',
    'Zwart leer',
    'Antraciet leer',
    'Blauw leer'
]).set('drieband', [
    'Hidro donkerblauw nubuck',
    'Zwart leer',
    'Antraciet leer',
    'Black panter velvet',
    'Taupe suede',
    'Montana cognac leather',
    'Hidro jeans nubuck',
    'Hidro oker rood nubuck',
    'Donkerbruin leer',
    'Groen leer',
    'Blauw leer'
]).set('drieband_hiel', [
    'Taupe suede',
    'Montana cognac leer',
    'Hidro jeans nubuck',
    'Hidro donkerblauw nubuck',
    'Hidro rood nubuck',
    'Zwart leer',
    'Antraciet leer',
    'Black panter velvet',
    'Donkerbruin leer',
    'Groen leer',
    'Blauw leer'
]).set('setubal', [
    'Naturex zwart leer',
    'Bruin suede'
])


export const fitsSize = {
    min: 34,
    max: 48
}
