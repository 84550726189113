import React, { useEffect, useRef, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Input,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Select,
    Checkbox,
    Box,
    MenuItem,
    InputBase,
    Tooltip,
    Autocomplete
} from '@mui/material'
import { Search } from '@mui/icons-material'
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useSelector, useDispatch } from 'react-redux'
import { patientData, generalAccordion, setError, resetPatientData } from '../../store/reducers/general'
import { resetDiagnosis, importDiagnosis, closeDiagnosis } from '../../store/reducers/diagnosis'
import { resetSole, closeSoles, importSole, soleData } from '../../store/reducers/soleSpecification'
import { resetSoleResult, importSoleResult, importSoleResultSuggestion, closeSoleResult } from '../../store/reducers/soleResult'
import { changeDataProvider, setPreviousOrders } from '../../store/reducers/other'
import { importAddition } from '../../store/reducers/soleAddition'
import { importFits, closeFits } from '../../store/reducers/fitsSpecification'

import { initialState as initDiagnose } from '../../store/reducers/diagnosis/initialState'
import { initialState as initSoleRes } from '../../store/reducers/soleResult/initialState'
import { initialState as initSole } from '../../store/reducers/soleSpecification/initialState'

import useStyles from './style'
import api from '../../lib/api'
import moment from 'moment'
import { importFitsToSole, importSoleToFits, importSoleAndFits } from '../../lib/importService'
import { withStyles } from '@mui/styles'
import { setSuggestion } from '../../store/reducers/importSuggestion'
import { deepCopy } from '../../lib/util'

const General = ({ classes }) => {
    const locationsListRef = useRef([])
    const [locationsNamesRef, setLocationsNamesRef] = useState([])

    // state management
    const dispatch = useDispatch()
    const orderType = useSelector((state) => state.general.orderType)
    const patient = useSelector((state) => state.general.patientData)
    const error = useSelector((state) => state.general.generalErrors)
    const tab = useSelector((state) => state.general.generalAccordion)
    const other = useSelector((state) => state.other)
    const sole = useSelector((state) => state.soleSpecification)
    const previousOrders = useSelector((state) => state.other.previousOrders)

    const [selectedPreviousOrder, setSelectedPreviousOrder] = useState('Geen')

    useEffect(() => {
        const locations = []
        switch (other.backend.key) {
        case 'Penders':
            locations.push({ id: -11, name: '(Penders) Opsturen pt via Hengelo', title: '(Penders) Opsturen pt via Hengelo', placeName: '' })
            locationsListRef.current = locations
            setLocationsNamesRef(locations.map(x => x.title))
            break
        case 'Voetmax':
            locations.push({ id: -8, name: 'Voetmax - Emmer-Compascuum', title: 'Voetmax - Emmer-Compascuum', placeName: '' })
            locations.push({ id: -9, name: 'Voetmax - Hengelo de Kievit', title: 'Voetmax - Hengelo de Kievit', placeName: '' })
            locations.push({ id: -10, name: 'Voetmax - Leiden de Kievit', title: 'Voetmax - Leiden de Kievit', placeName: '' })
            // locations.push({ id: -12, name: '', title: 'Voetmax (via Kievit Hengelo versturen naar patient)', placeName: '' })
            locations.push({ id: -12, name: '', title: '(.Voetmax) Sturen pt via Zoollogist ', placeName: '' })
            locationsListRef.current = locations
            setLocationsNamesRef(locations.map(x => x.title))
            break
        case 'Kievit Orthopedie':
            locations.push({ id: -13, name: 'Kievit Orthopedie Haren', title: 'Kievit Orthopedie Haren', placeName: '' })
            locationsListRef.current = locations
            setLocationsNamesRef(locations.map(x => x.title))
            break
        default:
            api.location((rsp) => {
                rsp.push({ id: -1, name: '', title: '(.VCW) Sturen pt via Zoollogistiek', placeName: '' })
                locationsListRef.current = rsp
                setLocationsNamesRef(rsp.map(x => x.title))
            }, (error) => {
                dispatch(setError(error))
            })
            break
        }
    }, [])

    useEffect(() => {
        if (!tab.accordion) {
            toggleAccordion()
            dispatch(generalAccordion({ key: 'validateFalse' }))
        }
    }, [])

    /**
     * Change the patient number.
     * On input of the patient number id this function if called searching for patients
     *
     * @param {Object} newInputValue the new input value
     * @returns
     */
    function onPatientNumberInput (patientNumber) {
        dispatch(patientData({ key: 'number', value: patientNumber }))
    }

    /**
     * Import sole property data.
     *
     * @param {Object} event -  return of soleproperties call.
     */
    function onSolePropertiesImported (event) {
        // Empty all properties
        dispatch(resetDiagnosis(initDiagnose))
        dispatch(resetSole(initSole))
        dispatch(resetSoleResult(initSoleRes))
        dispatch(changeDataProvider(other.backend.key))
        setSelectedPreviousOrder(event)
        let finalImport = event

        if (event === 'Geen') return
        let importOrderType = 'insole'

        if (event.orderType === 'fits' || event.orderType === 'fitsCustom' || event.orderType === 'fitsComfort') {
            importOrderType = 'fits'
        }

        if (importOrderType !== 'fits' && orderType !== 'fits' && finalImport.soleSpecification[0].block.key === '3D Sport Printzool') {
            dispatch(changeDataProvider('printsole'))
        }

        // Make changes to the import
        if (importOrderType === 'fits' && orderType !== 'fits') {
            finalImport = importFitsToSole(event)
        } else if (importOrderType !== 'fits' && orderType === 'fits') {
            finalImport = importSoleToFits(event)
        } else {
            finalImport = importSoleAndFits(event)
        }

        dispatch(patientData({ key: 'fittingLocation', value: finalImport.general.fittingLocation }))
        dispatch(patientData({ key: 'receiveLocation', value: finalImport.general.receiveLocation }))
        dispatch(patientData({ key: 'prefferedSupport', value: finalImport.general.prefferedSupport }))

        dispatch(importDiagnosis({ diagnosis: finalImport.diagnosis, version: event.version }))

        if (orderType === 'fits') dispatch(importFits(finalImport.fitsSpecification))
        if (orderType !== 'fits') dispatch(importSole({ soleSpecification: finalImport.soleSpecification, diagnosis: finalImport.diagnosis }))

        dispatch(importSoleResult(finalImport.soleResult))

        // before importing soleresult suggestion we should check a couple of things.
        dispatch(importSoleResultSuggestion(finalImport.soleResultSuggestion))

        if (event.version > '1.5.1' && importOrderType !== 'fits') {
            dispatch(importAddition(finalImport.soleAddition))
        }

        const validateFits = []
        if (orderType === 'fits') {
            finalImport.fitsSpecification.forEach((fitsData) => {
                if (importOrderType === 'fits') validateFits.push({ fitsData })
                if (importOrderType !== 'fits') validateFits.push({ ...fitsData })
            })
        }

        const validateSole = []
        if (orderType !== 'fits') {
            finalImport.soleSpecification.forEach((soleData) => {
                if (importOrderType === 'fits') validateSole.push({ ...soleData })
                if (importOrderType !== 'fits') validateSole.push({ soleData })
            })
        }

        dispatch(setSuggestion(finalImport.importSuggestion))

        dispatch(closeSoleResult({ diagnosis: finalImport.diagnosis, orderType, fits: validateFits, sole: validateSole }))
        dispatch(closeDiagnosis())
        dispatch(closeSoles())
        dispatch(closeFits())
        dispatch(generalAccordion({ key: 'validate' }))
    }

    /**
     * Update the date in the ui.
     *
     * @param {Date} date - date to update to.
     */
    function onBirthdateSelected (date) {
        const bday = moment(date).add(2, 'h')
        dispatch(patientData({ key: 'birthdate', value: date === null ? '' : bday }))
    }

    /**
     * Change the lastname of the patient.
     *
     * @param {String} lastName - value of the last name.
     */
    function onLastNameChanged (lastName) {
        dispatch(patientData({ key: 'lastName', value: lastName.replace(/\d+/g, '') }))
    }

    /**
     * Change the fitting location.
     *
     * @param selectedLocation
     */
    function onFittingLocationSelected (selectedLocation) {
        if (!selectedLocation) {
            dispatch(patientData({ key: 'fittingLocation', value: { id: 0, name: '', title: '', placeName: '' } }))
        } else {
            const selectedLocationObj = deepCopy(locationsListRef.current.find(location => location.title.toLowerCase() === selectedLocation.toLowerCase()))
            let receiveLocation = selectedLocation
            const name = selectedLocationObj.title
            if (other.backend === '' || other.backend.key === 'Voetencentrum Wender' || other.backend.key === 'Acceptatie') {
                if (name.includes('Kievit') || name.includes('kievit')) {
                    if (name.includes('Kind') || name.includes('kind') || name.includes('Amersfoort de Kievit') || name.includes('Amstelveen de Kievit') || name.includes('Leiden de Kievit') || name.includes('Enschede de Kievit')) {
                        receiveLocation = '(.VCW) Sturen pt via Zoollogistiek'
                    }
                } else {
                    receiveLocation = '(.VCW) Sturen pt via Zoollogistiek'
                }
            }
            selectedLocationObj.id = parseInt(selectedLocationObj.id)
            dispatch(patientData({ key: 'fittingLocation', value: selectedLocationObj }))
            onReceiveLocationSelected(receiveLocation)
        }
    }

    /**
     * Update second location.
     *
     * @param selectedLocation
     */
    function onReceiveLocationSelected (selectedLocation) {
        if (!selectedLocation) {
            dispatch(patientData({ key: 'receiveLocation', value: { id: 0, name: '', title: '', placeName: '' } }))
        } else {
            const selectedLocationObj = deepCopy(locationsListRef.current.find(location => location.title.toLowerCase() === selectedLocation.toLowerCase()))
            selectedLocationObj.id = parseInt(selectedLocationObj.id)
            dispatch(patientData({ key: 'receiveLocation', value: selectedLocationObj }))
        }
    }

    /**
     * Get patient data on request
     *
     * @returns null
     */
    function getPatientInfo () {
        api.patients(patient.number,
            (data) => {
                const bday = moment(data.birthDate).add(2, 'h')
                dispatch(patientData({ key: 'lastName', value: data.lastName }))
                dispatch(patientData({ key: 'birthdate', value: bday }))
                dispatch(patientData({ key: 'gender', value: data.sex.toLowerCase() }))
                dispatch(patientData({ key: 'prefferedSupport', value: data.sex.toLowerCase() }))
                dispatch(patientData({ key: 'id', value: `${data.id}` }))
                dispatch(patientData({ key: 'initials', value: `${data.initials}` }))

                if (data.footLengthLeft !== 0) {
                    for (let soleSpecNumber = 0; soleSpecNumber < 3; soleSpecNumber++) {
                        dispatch(soleData({ key: 'footSizeLeft', value: data.footLengthLeft, soleSpecNumber }))
                        dispatch(soleData({ key: 'footSizeRight', value: data.footLengthRight, soleSpecNumber }))
                    }
                }

                api.getOrder(data.id, (data) => {
                    const parsedData = []
                    data.forEach(element => {
                        parsedData.push(JSON.parse(element))
                    })
                    dispatch(setPreviousOrders(parsedData))
                }, (error) => {
                    dispatch(setError(error))
                })
            }, (error) => {
                dispatch(setError(error))
                dispatch(resetPatientData())
            })
    }

    /**
     * Change emergency order.
     */
    function onEmergencyOrderSelected () {
        const value = !patient.emergencyOrder
        dispatch(patientData({ key: 'emergencyOrder', value, sole }))
    }

    /**
     * Change value of gender.
     *
     * @param {Object} gender - Event value.
     */
    function onPatientGenderSelected (gender) {
        dispatch(patientData({ key: 'gender', value: gender }))
        dispatch(patientData({ key: 'prefferedSupport', value: gender }))
    }

    /**
     * Change value of preffered support.
     *
     * @param {Object} prefferedSupport - Event value.
     */
    function onPrefferedSupport (prefferedSupport) {
        dispatch(patientData({ key: 'prefferedSupport', value: prefferedSupport }))
    }

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(generalAccordion({ key: 'accordion', value: true }))
    }

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>Algemeen</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
                <FormGroup>
                    <Grid container spacing={4}>
                        <Grid item xs={5}>
                            <FormControl
                                fullWidth
                                error={error.number}
                                variant="standard"
                            >
                                <FormLabel>Patiëntnummer*</FormLabel>
                                <Tooltip title="Druk 'enter' om te zoeken">
                                    <div className={classes.search}>
                                        <Box onClick={() => { alert('zoeken') }} className={classes.searchIcon}>
                                            <Search />
                                        </Box>
                                        <InputBase
                                            placeholder="Zoeken..."
                                            className={classes.inputInput}
                                            value={patient.number}
                                            onKeyDownCapture={(event) => event.key === 'Enter' && getPatientInfo()}
                                            onChange={event => onPatientNumberInput(event.target.value)}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div>
                                </Tooltip>
                            </FormControl>
                        </Grid>
                        <Grid item xs={7}>
                            <FormControl
                                fullWidth
                            >
                                {other.dataProvider !== 'fits-comfort' && <FormLabel>Zolen importeren</FormLabel>}
                                {other.dataProvider === 'fits-comfort' && <FormLabel>Importeren is uitgeschakeld voor FITS comfort</FormLabel>}
                                <Select
                                    value={selectedPreviousOrder}
                                    disabled={other.dataProvider === 'fits-comfort'}
                                    onChange={event => onSolePropertiesImported(event.target.value)}
                                    className={classes.autoComplete}
                                >
                                    <MenuItem key={-1} value={'Geen'}>Geen</MenuItem>
                                    {
                                        previousOrders.map((order, index) =>
                                            <MenuItem
                                                key={index}
                                                value={order}>Datum: {moment(order.sendDate).format('yyyy-MM-DD - HH:mm')} {order.orderType === 'fitsComfort' || order.orderType === 'fitsCustom' || order.orderType === 'fits' ? '| FITS' : '| Zolen'}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.birthdate}
                            >
                                <FormLabel className={classes.formLabel}>Geboortedatum patiënt*</FormLabel>
                                <LocalizationProvider
                                    className={classes.autoComplete}dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        error={error.birthdate}
                                        value={patient.birthdate || Date.now('DD/MM/YYYYD')}
                                        onChange={onBirthdateSelected}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.gender}
                            >
                                <FormLabel>Geslacht patiënt*</FormLabel>
                                <Grid >
                                    <RadioGroup
                                        className={classes.autoComplete} row value={patient.gender} aria-label="gender" name="gender-select"
                                        onChange={(event) => onPatientGenderSelected(event.target.value)}>
                                        <FormControlLabel value="male" control={<Radio />} label="Man" />
                                        <FormControlLabel value="female" control={<Radio />} label="Vrouw" />
                                        {/* <FormControlLabel value="other" control={<Radio />} label="Genderneutraal" /> */}
                                    </RadioGroup>
                                </Grid>
                            </FormControl>
                            {orderType === 'fits' && other.dataProvider !== 'fits-comfort' && <FormControl
                                fullWidth
                                error={error.prefferedSupport}
                            >
                                <FormLabel>Voorkeur FITS</FormLabel>
                                <Grid>
                                    <RadioGroup row value={patient.prefferedSupport} aria-label="prefferedSupport" name="preffered-support-select"
                                        onChange={(event) => onPrefferedSupport(event.target.value)}>
                                        <FormControlLabel value="male" control={<Radio />} label="Heren" />
                                        <FormControlLabel value="female" control={<Radio />} label="Dames" />
                                    </RadioGroup>
                                </Grid>
                            </FormControl>}
                        </Grid>
                        <Grid item xs={1}>
                            <FormControl
                                fullWidth
                                error={error.lastName}
                            >
                                <FormLabel>Initialen</FormLabel>
                                <Box className={classes.inputField}></Box>
                                <Input
                                    type='text' value={patient.initials}
                                    disabled={true}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.lastName}
                            >
                                <FormLabel>Achternaam patiënt*</FormLabel>
                                <Box className={classes.inputField}></Box>
                                <Input
                                    type='text' value={patient.lastName}
                                    disabled={patient.number !== ''}
                                    onChange={event => onLastNameChanged(event.target.value)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.fittingLocation}
                            >
                                <FormLabel>Aanmeetlocatie*</FormLabel>
                                <Autocomplete
                                    name="aanmeet-locatie"
                                    id="locatie" label="locatie"
                                    value={patient.fittingLocation.title}
                                    onChange={(event, value) => onFittingLocationSelected(value)}
                                    options={locationsNamesRef}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.fittingLocation}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.receiveLocation}
                            >
                                <FormLabel>Ontvangstlocatie*</FormLabel>
                                <Autocomplete
                                    name="Awfijkende locatie" // Check if Ontvangstlocatie Name/ID is used elsewhere.
                                    id="afwijkende"
                                    value={patient.receiveLocation.title}
                                    onChange={(event, value) => onReceiveLocationSelected(value)}
                                    options={locationsNamesRef}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.receiveLocation}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        {
                            orderType !== 'fits'
                                ? <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel className={classes.postponeOrderCheckbox} label="Spoedbestelling" control={
                                            <Checkbox checked={patient.emergencyOrder}
                                                value={patient.emergencyOrder}
                                                onChange={onEmergencyOrderSelected}/>
                                        } />
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel label="Uitgesteld bestellen (2025)" control={
                                            <Checkbox checked={patient.postponeOrder}
                                                value={patient.postponeOrder}
                                                onChange={() => { dispatch(patientData({ key: 'postponeOrder', value: !patient.postponeOrder })) }}/>
                                        } />
                                    </FormControl>
                                </Grid>
                                : <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel className={classes.checkBox} label="Uitgesteld bestellen (2025)" control={
                                            <Checkbox checked={patient.postponeOrder}
                                                value={patient.postponeOrder}
                                                onChange={() => { dispatch(patientData({ key: 'postponeOrder', value: !patient.postponeOrder })) }}/>
                                        } />
                                    </FormControl>
                                </Grid>
                                // null
                        }
                    </Grid>
                </FormGroup>
                {/* </MuiPickersUtilsProvider> */}
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(General)
