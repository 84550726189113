export const initialState = {
    soleSpecification: [
        {},
        {},
        {}
    ],
    fitsSpecification: [
        {},
        {},
        {}
    ]
}
