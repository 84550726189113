{
  "name": "sim-app",
  "version": "1.9.0",
  "private": true,
  "license": "UNLICENSED",
  "dependencies": {
    "@date-io/moment": "^1.3.13",
    "@emotion/react": "^11.11.1",
    "@emotion/styled": "^11.11.0",
    "@mui/base": "^5.0.0-beta.20",
    "@mui/icons-material": "^5.14.14",
    "@mui/lab": "^5.0.0-alpha.149",
    "@mui/material": "^5.14.14",
    "@mui/styles": "^5.14.14",
    "@mui/system": "^5.14.14",
    "@mui/x-date-pickers": "^6.16.2",
    "@react-three/drei": "^9.88.4",
    "@react-three/fiber": "^8.14.2",
    "@reduxjs/toolkit": "^1.9.7",
    "@vitejs/plugin-react-swc": "^3.7.2",
    "axios": "^0.27.2",
    "closest-match": "^1.3.3",
    "esbuild": "^0.19.5",
    "esbuild-plugin-inline-image": "^0.0.9",
    "eslint-config-standard": "^17.1.0",
    "exceljs": "^4.4.0",
    "jszip": "^3.10.1",
    "keycloak-js": "^21.1.1",
    "moment": "^2.29.4",
    "notistack": "^3.0.1",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-hotkeys-hook": "^3.4.6",
    "react-redux": "^8.1.3",
    "react-router-dom": "^6.17.0",
    "redux": "^4.2.1",
    "three": "^0.144.0",
    "vitest": "^2.1.8"
  },
  "scripts": {
    "start": "vite",
    "build": "node build.js",
    "test": "vitest run",
    "testWatch": "vitest watch"
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@eslint/compat": "^1.2.4",
    "@eslint/eslintrc": "^3.2.0",
    "@eslint/js": "^9.17.0",
    "@stylistic/eslint-plugin": "^2.12.1",
    "@testing-library/react": "^11.2.7",
    "@testing-library/user-event": "^12.8.3",
    "@types/node": "^22.10.2",
    "@types/react": "^19.0.2",
    "@types/react-dom": "^19.0.2",
    "@typescript-eslint/parser": "^8.18.1",
    "@vitest/ui": "^2.1.8",
    "eslint": "^9.17.0",
    "eslint-config-standard": "^17.1.0",
    "eslint-plugin-import": "^2.26.0",
    "eslint-plugin-jsdoc": "^50.6.1",
    "eslint-plugin-n": "^15.3.0",
    "eslint-plugin-promise": "^7.2.1",
    "eslint-plugin-react": "^7.37.2",
    "eslint-plugin-react-redux": "^4.2.0",
    "globals": "^15.14.0",
    "typescript": "^5.7.2",
    "typescript-eslint": "^8.18.1",
    "vite": "^6.0.5",
    "vite-tsconfig-paths": "^5.1.4"
  },
  "packageManager": "yarn@1.22.22+sha512.a6b2f7906b721bba3d67d4aff083df04dad64c399707841b7acf00f6b133b7ac24255f2652fa22ae3534329dc6180534e98d17432037ff6fd140556e2bb3137e"
}
