import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateAllFields } from './validation.js'

const additionSlice = createSlice({
    name: 'addition',
    initialState,
    reducers: {
        additionData (state, action) {
            const key = action.payload.key
            const number = action.payload.number
            const value = action.payload.value
            switch (key) {
            case 'soleAddition' :
                state.additionData.addition[number].name = value
                break
            case 'soleLocation' :
                state.additionData.addition[number].location = value
                break
            case 'addAddition': case 'removeAddition' :
                state.additionData.addition = value
                state.additionErrors.addition[value.length - 1] = false
                break
            case 'addSole' :
                state.additionData.addition[number].forSoles = value
                break
            default:
                state.additionData[key] = value
            }
        },
        additionAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                state.additionAccordion.accordion = action.payload.value.accordion
                if (!state.additionAccordion.hasBeenOpened) {
                    state.additionAccordion.hasBeenOpened = true
                } else {
                    validateAllFields(state, action)
                }
                break
            case 'validate':
                state.additionAccordion.validate = action.payload.value
                break
            case 'hasBeenOpened':
                state.additionAccordion.hasBeenOpened = true
                break
            }
        },
        setError (state, action) {
            state.additionErrors.display = true
            state.additionErrors.message = action.payload.message
        },
        importAddition (state, action) {
            state.additionData = action.payload
            state.additionAccordion.accordion = false
            state.additionAccordion.hasBeenOpened = true
        },
        closeAddition (state, action) {
            state.additionAccordion.accordion = false
            state.additionAccordion.hasBeenOpened = true
            validateAllFields(state, action)
        }
    }
})

export const { additionAccordion, additionData, closeAddition, setError, importAddition } = additionSlice.actions
export default additionSlice.reducer
