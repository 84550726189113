import { initialState as initialStateFits } from '../store/reducers/fitsSpecification/initialState'
import { initialState as initialStateSole } from '../store/reducers/soleSpecification/initialState'
import { deepCopy } from './util'
import { distance } from 'closest-match'

import data from '../tabs/diagnosis/diagnose'
import dataProvider from './dataProvider'
import { fittingData } from './fittingData'

const fitsCoverMaterials = dataProvider.fitsSpecification.coverMaterial
const finishingShapeOptions = dataProvider.fitsSpecification.finishingShapeOptions
const modelChoices = dataProvider.fitsSpecification.modelChoices
const upperMaterialOptions = dataProvider.fitsSpecification.upperMaterialOptions
const fitsBlockChoices = dataProvider.fitsSpecification.blockChoices
const fitsOutsoleChoices = dataProvider.fitsSpecification.outsoleChoices
const groundSolePatternTypes = dataProvider.soleSpecification.groundSolePatternTypes
const soleCoverMaterials = dataProvider.soleSpecification.coverMaterials
const soleBlockChoices = dataProvider.soleSpecification.blockChoices

const locationsReplaceMap = new Map([
    [-1, { id: -1, name: '', title: '(.VCW) Sturen pt via Zoollogistiek', placeName: '' }]
])

/**
 * Common functionality for updating general information of the import process
 * for all footwear types.
 *
 * @param {Object} general The general info
 * @return {Object} The updated general info
 */
function updateGeneralInfo (general) {
    general.fittingLocation.id = parseInt(general.fittingLocation.id)
    general.receiveLocation.id = parseInt(general.receiveLocation.id)

    // Update location information
    general.fittingLocation = locationsReplaceMap.get(general.fittingLocation.id) ?? general.fittingLocation
    return general
}

/**
 * Change the import to work on this current version
 *
 * @param {Object} event - full JSON import file.
 * @return state
 */
function importSoleAndFits (event) {
    const finalImport = deepCopy(event)
    finalImport.general = updateGeneralInfo(finalImport.general)

    let diagnosis = data.getDiagnoseByName(finalImport.diagnosis.diagnosis.name)
    if (diagnosis === undefined) {
        // get a diagnosis with the full string
        diagnosis = data.getClosestsDiagnosis(finalImport.diagnosis.diagnosis.name)

        // if diagnosis is still undefined, check if which diagnosis is closest to the name.
        if (diagnosis === undefined) {
            let closestDiagnosisMatch = { name: '' }
            const parsedDiagnosisName = finalImport.diagnosis.diagnosis.name.replace(/[^a-zA-Z]+/g, '')
            data.diagnoses.forEach(element => {
                const elementParsedName = element.name.replace(/[^a-zA-Z]+/g, '')
                const distanceNumber = distance(elementParsedName, parsedDiagnosisName)
                if (distanceNumber < distance(closestDiagnosisMatch.name.replace(/[^a-zA-Z]+/g, ''), parsedDiagnosisName)) {
                    closestDiagnosisMatch = element
                }
            })

            // distance margin
            if (distance(closestDiagnosisMatch.name.replace(/[^a-zA-Z]+/g, ''), parsedDiagnosisName) < 4) {
                finalImport.diagnosis.diagnosis = closestDiagnosisMatch
            }
        } else {
            finalImport.diagnosis.diagnosis = diagnosis
        }
    } else {
        finalImport.diagnosis.diagnosis = diagnosis
    }

    if (finalImport.diagnosis.extraDiagnosis === undefined) {
        finalImport.diagnosis.extraDiagnosis = []
    }

    if (finalImport.diagnosis.completePair === undefined) {
        finalImport.diagnosis.completePair = 'both'
    }

    finalImport.importSuggestion = { soleSpecification: [{}, {}, {}], fitsSpecification: [{}, {}, {}] }

    //  fix import for old version
    if (event.version <= '1.4.5') {
        // make sole result and sole result suggestion an array, that is used like an array now.
        finalImport.soleResult = deepCopy([event.soleResult, event.soleResult, event.soleResult])
        finalImport.soleResultSuggestion = deepCopy([event.soleResultSuggestion, event.soleResultSuggestion, event.soleResultSuggestion])
    }

    // Fix preffered support to be the same as gender because default it was male.
    if (event.version <= '1.5.9') {
        finalImport.general.prefferedSupport = finalImport.general.gender
    }

    // get current naming of model choices
    if (event.orderType === 'fits' || event.orderType === 'fitsCustom') {
        const gender = event.general.prefferedSupport !== undefined ? event.general.prefferedSupport : event.general.gender

        finalImport.fitsSpecification.forEach((fitsSpec, fitsNumber) => {
            const fitsImportSuggestion = finalImport.importSuggestion.fitsSpecification[fitsNumber]

            if (event.version <= '1.5.3') {
                fitsSpec.extraElement = fitsSpec.extraElement === true ? ['30sh 3mm EVA - zwart'] : []
            }

            if (event.version <= '1.5.5') {
                fitsSpec.millingSize = fitsSpec.millSize
                delete fitsSpec.millSize
            }

            if (event.version <= '1.5.9') {
                fitsSpec.soleSizeLeft = fitsSpec.footSizeLeft
                fitsSpec.soleSizeRight = fitsSpec.footSizeRight
            }

            // Try to match the fits model, otherwise make a suggestion
            const expectedFitsModel = fitsSpec.model.displayValue
            const fitsModel = modelChoices.get(gender).find(element => element.displayValue === expectedFitsModel) ?? ''
            let uppermatChoices
            fitsSpec.model = fitsModel ?? ''

            if (fitsModel === '') {
                fitsImportSuggestion.model = expectedFitsModel
                // The choice of uppermaterials depends on the fitsModel, but the fitsModel is undefined, so there are no choices
                uppermatChoices = []
            } else {
                // Get uppermaterials (this depends on the fitsModel)
                uppermatChoices = upperMaterialOptions.get(gender).get(fitsSpec.model.key)
            }

            /**
             * @callback findAlternativeCb
             * @param {any} value The imported value
             * @returns {any} The fixed-up value
             */

            /**
             * Updates the specification based on the imported value, limited to the given choices.
             * If the imported value is not in the list of choices, the callback optionally gets invoked which can try
             * to provide an alternative. If that does not provide a valid choice either, the `importSuggestion` is updated
             * and the specification is updated with an empty string.
             *
             * @param {string} part The part to update, as key of the `fitsSpec` object
             * @param {Array} choices The allowed choices the part is allowed to be
             * @param {findAlternativeCb} [findAlternativeCb] callback to supply an alternative if the imported value is not a valid choice
             */
            const importValueWithChoices = (part, choices, findAlternativeCb) => {
                // Try to find the desired value in the list of choices
                const importedValue = fitsSpec[part]
                fitsSpec[part] = choices.find(e => e === importedValue) ?? ''

                if (fitsSpec[part] === '') {
                    // The desired value is not found, try invoking the callback if it is set
                    if (findAlternativeCb !== undefined) {
                        const alternative = findAlternativeCb(importedValue)
                        fitsSpec[part] = choices.find(e => e === alternative) ?? ''

                        if (fitsSpec[part] === '') {
                            // The alternative is not in the list of choices either
                            fitsImportSuggestion[part] = importedValue
                        }
                    } else {
                        fitsImportSuggestion[part] = importedValue
                    }
                }
            }

            importValueWithChoices('upperMaterial', uppermatChoices)
            importValueWithChoices('finishingShape', finishingShapeOptions)
            importValueWithChoices('block', fitsBlockChoices)
            importValueWithChoices('outsole', fitsOutsoleChoices)
            importValueWithChoices('coverMaterial', fitsCoverMaterials, (coverMat) => {
                // remove microfiber from string, since its no longer there.
                let splittedCoverMaterial = coverMat.toLowerCase().split('microfiber ')

                // remove spaces.
                splittedCoverMaterial = splittedCoverMaterial.toString().split(' ')

                // gather all materials matching the first string.
                const materials = fitsCoverMaterials.filter(element => element.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(splittedCoverMaterial[0]))

                // finalize the material with one type that matches with the second string.
                return materials.find(element => element.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(splittedCoverMaterial.pop())) || ''
            })

            finalImport.fitsSpecification[fitsNumber] = fitsSpec
            finalImport.importSuggestion.fitsSpecification[fitsNumber] = fitsImportSuggestion
        })
    }

    if (event.orderType !== 'fitsCustom' && event.orderType !== 'fitsComfort' && event.orderType !== 'fits') {
        // loop through solespecifications
        finalImport.soleSpecification.forEach((sole, i) => {
            const soleSpec = i

            if (event.version <= '1.6.1') {
                for (const key in sole) {
                    if (key === 'millingSize') {
                        if (finalImport.soleSpecification[soleSpec].block.includes('3D PU')) {
                            finalImport.soleSpecification[soleSpec][key] = 38
                        }

                        // empty milling size if 16 is filled
                        if (finalImport.soleSpecification[soleSpec].millingSize === 16) {
                            finalImport.soleSpecification[soleSpec].millingSize = ''
                        }
                    }
                }
            }

            finalImport.soleSpecification[soleSpec].millingSize = finalImport.soleSpecification[soleSpec].millingSize === 32 ? 30 : finalImport.soleSpecification[soleSpec].millingSize

            let block = ''
            if (event.version <= '1.6.2') {
                block = soleBlockChoices.find(element => element.key === finalImport.soleSpecification[soleSpec].block) || ''

                // check if block is exeption EVA 50 sh
                if (finalImport.soleSpecification[soleSpec].block === 'EVA 50sh zwart' || finalImport.soleSpecification[soleSpec].block === 'EVA 50 sh - zwart') {
                    block = soleBlockChoices.find(element => element.key === 'EVA 50 sh')
                }
            } else {
                block = soleBlockChoices.find(element => element.key === finalImport.soleSpecification[soleSpec].block.key) || ''

                // check if block is exeption EVA 50 sh
                if (finalImport.soleSpecification[soleSpec].block.key === 'EVA 50 sh - zwart') {
                    block = soleBlockChoices.find(element => element.key === 'EVA 50 sh')
                }
            }

            if (block === '') {
                if (event.version <= '1.6.2') {
                    finalImport.importSuggestion.soleSpecification[soleSpec].block = finalImport.soleSpecification[soleSpec].block
                } else {
                    finalImport.importSuggestion.soleSpecification[soleSpec].block = finalImport.soleSpecification[soleSpec].block.key
                }
                finalImport.soleSpecification[soleSpec].block = { key: '', soleThickness: 0.7, millingSize: 30, coverMaterial: 'Su\xE8de grijs' }
            } else {
                finalImport.soleSpecification[soleSpec].block = block
            }

            let groundSole = groundSolePatternTypes.find(element => (element.name === finalImport.soleSpecification[soleSpec].groundSole.name) && (element.publock === finalImport.soleSpecification[soleSpec].block.key.includes('3D PU'))) || ''

            if (finalImport.soleSpecification[soleSpec].block.key === '3D Sport Printzool') {
                groundSole = finalImport.soleSpecification[soleSpec].groundSole
            }

            if (groundSole === '') {
                if (finalImport.soleSpecification[soleSpec].groundSole.name.includes(' (Alleen voor OS)')) {
                    finalImport.soleSpecification[soleSpec].groundSole = groundSolePatternTypes.find(element => element.name === finalImport.soleSpecification[soleSpec].groundSole.name.replace(' (Alleen voor OS)', '')) || ''
                } else if (finalImport.soleSpecification[soleSpec].groundSole.name.includes('GP Kids')) {
                    finalImport.soleSpecification[soleSpec].groundSole = groundSolePatternTypes.find(element => element.name === 'PLT Kids') || ''
                } else if (finalImport.soleSpecification[soleSpec].groundSole.name === 'PLT Smal Sport') {
                    finalImport.soleSpecification[soleSpec].groundSole = groundSolePatternTypes.find(element => element.name === 'PLT Small Sport') || ''
                } else {
                    finalImport.importSuggestion.soleSpecification[soleSpec].groundSole = finalImport.soleSpecification[soleSpec].groundSole.name
                    finalImport.soleSpecification[soleSpec].groundSole = {
                        name: '',
                        min: 28,
                        max: 50,
                        publock: false,
                        sizeSystem: 'EU',
                        fittingData: fittingData['PLT Elegant']
                    }
                }
            } else {
                finalImport.soleSpecification[soleSpec].groundSole = groundSole
            }

            if (finalImport.soleSpecification[0].block.key !== '3D Sport Printzool') {
                const coverMat = soleCoverMaterials.find(element => element === finalImport.soleSpecification[soleSpec].coverMaterial) || ''
                // if material not found, try to find something similar.
                if (coverMat === '') {
                    // remove microfiber from string, since its no longer there.
                    let splittedCoverMaterial = event.soleSpecification[soleSpec].coverMaterial.toLowerCase().split('microfiber ')

                    // remove spaces.
                    splittedCoverMaterial = splittedCoverMaterial.toString().replace(',', '').split(' ')

                    // gather all materials matching the first string.
                    const materials = soleCoverMaterials.filter(element => element.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(splittedCoverMaterial[0]))

                    const lastMaterialValue = splittedCoverMaterial.pop()
                    const findFinalMaterial = materials.find(element => element.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(lastMaterialValue))

                    if (findFinalMaterial === undefined || findFinalMaterial === '') {
                        finalImport.importSuggestion.soleSpecification[soleSpec].coverMaterial = finalImport.soleSpecification[soleSpec].coverMaterial
                        finalImport.soleSpecification[soleSpec].coverMaterial = ''
                    } else {
                        // finalize the material with one type that matches with the second string.
                        finalImport.soleSpecification[soleSpec].coverMaterial = findFinalMaterial || ''
                    }
                } else {
                    finalImport.soleSpecification[soleSpec].coverMaterial = coverMat
                }
            }

            if (event.version <= '1.6.2') {
                finalImport.soleSpecification[soleSpec].soleSizeLeft = `${finalImport.soleSpecification[soleSpec].soleSizeLeft}`.replace(',', '.')
                finalImport.soleSpecification[soleSpec].soleSizeRight = `${finalImport.soleSpecification[soleSpec].soleSizeRight}`.replace(',', '.')
                finalImport.soleSpecification[soleSpec].footSizeLeft = `${finalImport.soleSpecification[soleSpec].footSizeLeft}`.replace(',', '.')
                finalImport.soleSpecification[soleSpec].footSizeRight = `${finalImport.soleSpecification[soleSpec].footSizeRight}`.replace(',', '.')
            }

            if (finalImport.soleSpecification[soleSpec].hypoallergenicGlue === undefined) finalImport.soleSpecification[soleSpec].hypoallergenicGlue = false
        })

        // loop through soleresult
        finalImport.soleResult.forEach((soleResultData, i) => {
            for (const key in soleResultData.left) {
                if (key === 'fasciPad' || key === 'ascPad') {
                    if (typeof soleResultData.left[key] === 'boolean') {
                        finalImport.soleResult[i].left[key] = `${finalImport.soleResult[i].left[key]}`
                    }
                    if (typeof soleResultData.right[key] === 'boolean') {
                        finalImport.soleResult[i].right[key] = `${finalImport.soleResult[i].right[key]}`
                    }
                }

                if (key === 'CV') {
                    if (finalImport.soleResult[i].left[key] > 0) {
                        const diff = 10 - finalImport.soleResult[i].left[key]
                        if (diff < 0) {
                            finalImport.soleResult[i].left[key] = 10
                        }
                    }
                    if (finalImport.soleResult[i].right[key] > 0) {
                        const diff = 10 - finalImport.soleResult[i].right[key]
                        if (diff < 0) {
                            finalImport.soleResult[i].right[key] = 10
                        }
                    }
                }

                // Move old PA to LFW and remove old values aswell as suggestions
                if (event.version <= '1.8.0') {
                    if (key === 'PA') {
                        finalImport.soleResult[i].left.LFW = finalImport.soleResult[i].left.PA
                        finalImport.soleResult[i].right.LFW = finalImport.soleResult[i].right.PA

                        finalImport.soleResultSuggestion[i].left.LFW = finalImport.soleResultSuggestion[i].left.PA
                        finalImport.soleResultSuggestion[i].right.LFW = finalImport.soleResultSuggestion[i].right.PA

                        delete finalImport.soleResult[i].left.PA
                        delete finalImport.soleResult[i].right.PA
                        delete finalImport.soleResultSuggestion[i].left.PA
                        delete finalImport.soleResultSuggestion[i].right.PA
                    }

                    if (key === 'pelotte') {
                        if (finalImport.soleResult[i].left.pelotte !== '' && finalImport.soleResult[i].left.pelotte > 0) {
                            finalImport.soleResult[i].left.pelotteType = 'pelotte'
                        }
                        if (finalImport.soleResult[i].right.pelotte !== '' && finalImport.soleResult[i].right.pelotte > 0) {
                            finalImport.soleResult[i].right.pelotteType = 'pelotte'
                        }
                    }

                    if (key === 'MIC') {
                        if (finalImport.soleResultSuggestion[i].left.MICPosition === undefined) {
                            finalImport.soleResult[i].left.MICPosition = finalImport.diagnosis.diagnosis.MICPosition
                        }
                        if (finalImport.soleResultSuggestion[i].right.MICPosition === undefined) {
                            finalImport.soleResult[i].right.MICPosition = finalImport.diagnosis.diagnosis.MICPosition
                        }
                    }
                }

                // Handle pelotteType name change before version 1.8.2
                if (event.version >= '1.8.0' && event.version <= '1.8.2') {
                    const pelotteTypeRe = /^Interdigitale pelotte (\d+)-(\d+)$/
                    const pelotteTypeReplacement = 'Intermetatarsale pelotte $1-$2'

                    finalImport.soleResultSuggestion[i].left.pelotteType = finalImport.soleResultSuggestion[i].left.pelotteType.replace(pelotteTypeRe, pelotteTypeReplacement)
                    finalImport.soleResultSuggestion[i].right.pelotteType = finalImport.soleResultSuggestion[i].right.pelotteType.replace(pelotteTypeRe, pelotteTypeReplacement)
                }
            }
        })

        if (finalImport.soleAddition !== undefined) {
            finalImport.soleAddition.addition.forEach((addition, i) => {
                finalImport.soleAddition.addition[i].name = addition.name.replace('Shockfoam', 'PPT')

                if (finalImport.soleAddition.addition[i].name.includes('Softlux')) {
                    finalImport.soleAddition.addition[i].name = ''
                }
            })
        }
    }
    // check soletherapy
    return finalImport
}

/**
 * Change insole import to work with the fits version.
 *
 * @param {Object} event - full JSON import file.
 * @return state
 */
function importSoleToFits (event) {
    const finalImport = deepCopy(event)
    finalImport.general = updateGeneralInfo(finalImport.general)

    //  fix import for old version
    if (event.version <= '1.4.5') {
        // make sole result and sole result suggestion an array, that is used like an array now.
        finalImport.soleResult = [event.soleResult, event.soleResult, event.soleResult]
        finalImport.soleResultSuggestion = [event.soleResultSuggestion, event.soleResultSuggestion, event.soleResultSuggestion]
    }

    // Fix preffered support to be the same as gender because default it was male.
    if (event.version <= '1.5.9') {
        finalImport.general.prefferedSupport = finalImport.general.gender
    }

    if (finalImport.diagnosis.extraDiagnosis === undefined) {
        finalImport.diagnosis.extraDiagnosis = []
    }

    // if advised fasci or ascpad, ASC 9mm else ASC 6mm
    // show 507 error when own choice ASC <  6mm
    const diagnosis = data.getDiagnoseByName(finalImport.diagnosis.diagnosis.name)
    const init = deepCopy(initialStateFits)
    finalImport.fitsSpecification = [
        { ...init },
        { ...init },
        { ...init }
    ]

    // run through the solespecs and update the sole/ foot sizes
    event.soleSpecification.forEach((element, i) => {
        const fitsNumber = i

        finalImport.fitsSpecification[fitsNumber].fitsData.soleSizeLeft = `${element.soleSizeLeft}`.replace(',', '.')
        finalImport.fitsSpecification[fitsNumber].fitsData.soleSizeRight = `${element.soleSizeRight}`.replace(',', '.')
        finalImport.fitsSpecification[fitsNumber].fitsData.footSizeLeft = `${element.footSizeLeft}`.replace(',', '.')
        finalImport.fitsSpecification[fitsNumber].fitsData.footSizeRight = `${element.footSizeRight}`.replace(',', '.')
    })

    finalImport.soleResult.forEach((fitsResultData, i) => {
        const minimumValue = diagnosis.fasciPad === 'true' || diagnosis.ascPad === 'true' ? 9 : 6
        const diff = Math.abs(finalImport.soleResult[i].left.ASC - finalImport.soleResult[i].right.ASC)
        if (finalImport.soleResult[i].left.ASC > finalImport.soleResult[i].right.asc) {
            finalImport.soleResult[i].left.ASC = minimumValue + diff
            finalImport.soleResult[i].right.ASC = minimumValue

            finalImport.soleResultSuggestion[i].left.ASC = minimumValue
            finalImport.soleResultSuggestion[i].right.ASC = minimumValue
        } else {
            finalImport.soleResult[i].right.ASC = minimumValue + diff
            finalImport.soleResult[i].left.ASC = minimumValue

            finalImport.soleResultSuggestion[i].left.ASC = minimumValue
            finalImport.soleResultSuggestion[i].right.ASC = minimumValue
        }
    })

    // diagnosis will be handled in the store, because it will send an error message.
    if (diagnosis !== undefined) {
        finalImport.soleResultSuggestion.forEach((elements, i) => {
            const soleResNumber = i

            finalImport.soleResultSuggestion[soleResNumber].left.cv = 23
            finalImport.soleResultSuggestion[soleResNumber].right.cv = 23

            for (const key in elements.left) {
                switch (key) {
                case 'fasciPad': case 'ascPad':
                    finalImport.soleResultSuggestion[soleResNumber].left[key] = diagnosis[key]
                    finalImport.soleResultSuggestion[soleResNumber].right[key] = diagnosis[key]
                    finalImport.soleResult[soleResNumber].left[key] = diagnosis[key]
                    finalImport.soleResult[soleResNumber].right[key] = diagnosis[key]
                    break
                default:
                    break
                }
            }
        })

        const fullDiagnosis = deepCopy(finalImport.diagnosis)

        if (diagnosis.therapyMessage !== 0) {
            fullDiagnosis.therapyMessage = 'FITS Therapie'
            finalImport.diagnosis = fullDiagnosis
        } else {
            fullDiagnosis.therapyMessage = 'Geen FITS therapie'
            finalImport.diagnosis = fullDiagnosis
        }
    }

    // check soletherapy
    return finalImport
}

/**
 * Change fits import to work with the insole version.
 *
 * @param {Object} event - full JSON import file.
 * @return state
 */
function importFitsToSole (event) {
    const finalImport = deepCopy(event)
    finalImport.general = updateGeneralInfo(finalImport.general)

    //  fix import for old version
    if (event.version <= '1.4.5') {
        // make sole result and sole result suggestion an array, that is used like an array no
        finalImport.soleResult = [event.soleResult, event.soleResult, event.soleResult]
        finalImport.soleResultSuggestion = [event.soleResultSuggestion, event.soleResultSuggestion, event.soleResultSuggestion]
    }

    if (finalImport.diagnosis.extraDiagnosis === undefined) {
        finalImport.diagnosis.extraDiagnosis = []
    }

    if (finalImport.diagnosis.completePair === undefined) {
        finalImport.diagnosis.completePair = 'both'
    }

    const diagnosis = data.getDiagnoseByName(finalImport.diagnosis.diagnosis.name)
    const init = deepCopy(initialStateSole)
    finalImport.soleSpecification = [
        { ...init },
        { ...init },
        { ...init }
    ]

    // run through the solespecs and update the sole/ foot sizes
    event.fitsSpecification.forEach((element, i) => {
        const soleResNumber = i

        if (event.version <= '1.5.9') {
            finalImport.soleSpecification[soleResNumber].soleData.soleSizeLeft = `${element.footSizeLeft}`.replace(',', '.')
            finalImport.soleSpecification[soleResNumber].soleData.soleSizeRight = `${element.footSizeRight}`.replace(',', '.')
        } else {
            finalImport.soleSpecification[soleResNumber].soleData.soleSizeLeft = `${element.soleSizeLeft}`.replace(',', '.')
            finalImport.soleSpecification[soleResNumber].soleData.soleSizeRight = `${element.soleSizeRight}`.replace(',', '.')
        }

        finalImport.soleSpecification[soleResNumber].soleData.footSizeLeft = `${element.footSizeLeft}`.replace(',', '.')
        finalImport.soleSpecification[soleResNumber].soleData.footSizeRight = `${element.footSizeRight}`.replace(',', '.')
    })

    // diagnosis will be handled in the store, because it will send an error message.
    if (diagnosis !== undefined) {
        finalImport.soleResultSuggestion.forEach((elements, i) => {
            const soleResNumber = i
            for (const key in elements.left) {
                switch (key) {
                case 'cv':
                    finalImport.soleResultSuggestion[soleResNumber].left[key] = diagnosis.CV // check diagnosis list.
                    finalImport.soleResultSuggestion[soleResNumber].right[key] = diagnosis.CV // check diagnosis list.
                    break
                case 'asc':
                    finalImport.soleResultSuggestion[soleResNumber].left[key] = diagnosis.ASC // check diagnosis list.
                    finalImport.soleResultSuggestion[soleResNumber].right[key] = diagnosis.ASC // check diagnosis list.
                    break
                case 'fasciPad': case 'ascPad':
                    // PUT TO FALSE INSTEAD OF DIAGNOSE BECAUSE, THE DEFAULT BLOCK WILL BE OVERWRITTEN WHEN A DIFFRENT BLOCK IS USED.
                    // AND DEFAULT BLOCK 3D PU BLOCK DOES NOT SUPPORT FASCIPAD AND ASCPAD
                    finalImport.soleResultSuggestion[soleResNumber].left[key] = 'false'
                    finalImport.soleResultSuggestion[soleResNumber].right[key] = 'false'
                    finalImport.soleResult[soleResNumber].left[key] = 'false'
                    finalImport.soleResult[soleResNumber].right[key] = 'false'
                    break
                default:
                    break
                }
            }
        })

        const fullDiagnosis = deepCopy(finalImport.diagnosis)

        if (diagnosis.therapyMessage !== 0) {
            fullDiagnosis.therapyMessage = 'Zolentherapie'
            finalImport.diagnosis = fullDiagnosis
        } else {
            fullDiagnosis.therapyMessage = 'Geen zolentherapie'
            finalImport.diagnosis = fullDiagnosis
        }
    }

    // check soletherapy
    return finalImport
}

export { importSoleToFits, importFitsToSole, importSoleAndFits }
