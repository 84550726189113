import React, { useEffect, useState } from 'react'
import { ThemeProvider as MuiThemeProvider, Container, Box, Snackbar, Alert, AlertTitle } from '@mui/material'
import './style.css'
import CssBaseline from '@mui/material/CssBaseline'
import { useSelector } from 'react-redux'

// pages
import Overview from './OverviewPage/index'

// Styling
import useStyles from './style'
import { Routes, Route } from 'react-router-dom'
import HomePage from './HomePage'
import InsolePage from './InsolePage'
import FitsPage from './FitsPage'
import AppTitle from '../components/AppTitle'
import { LOGOUT_ENDPOINT } from '../env'
import ThemeProvider from '../lib/themeProvider'
import DataProvider from '../lib/dataProvider'
import { withStyles } from '@mui/styles'
import UserService from '../lib/userService'
import api from '../lib/api'
import moment from 'moment'
import BreakTheGlassDialog from '../components/BreakTheGlassDialog'

/**
 * Logout function
 *
 * @return null
 */
function Logout () {
    window.location.replace(LOGOUT_ENDPOINT)
    return null
}

const App = () => {
    const [closeToExpired, setCloseToExpired] = useState(false)
    const [expired, setExpired] = useState(false)
    const [expiresAt, setExpiresAt] = useState(moment())
    const other = useSelector((state) => state.other)
    const theme = ThemeProvider.theme(other.backend)
    useEffect(() => {
        UserService.refreshProces(checkSharepoint)
    }, [])
    DataProvider.setFolder(other.dataProvider)

    /**
     *
     */
    function checkSharepoint() {
        if (UserService.hasRole('sharepoint-tester')) {
            if(api.expires_at === "") return
            const date = moment.now()
            setExpiresAt(moment(api.expires_at))

            if(((api.expires_at - date) / 1000) < 180) {
                setExpired(true)
                setCloseToExpired(false)
                return
            } else if (((api.expires_at - date) / 1000) > 180 && ((api.expires_at - date) / 1000) < 600){
                setCloseToExpired(true)
                setExpired(false)
                return
            }
            setCloseToExpired(false)
            setExpired(false)
        }
    }

    /**
     *
     */
    function refreshSession() {
        if (UserService.hasRole('sharepoint-tester')) {
            window.location.replace(api.accountLinkUrl)
        } else {
            window.location.replace('/')
        }
    }

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Container >
                <Box>
                    <AppTitle/>
                    <Routes>
                        <Route path="/:number/:environment" element={<HomePage />} />
                        <Route path="/:number" element={<HomePage />} />
                        <Route path="/" element={<HomePage />} />
                        <Route path="/insole" element={<InsolePage />} />
                        <Route path="/fits" element={<FitsPage />} />
                        <Route path="/order" element={<Overview />} />
                        <Route path="/logout" element={<Logout/>}/>
                    </Routes>
                    <Snackbar
                        open={closeToExpired}
                    >
                        <Alert variant="filled" severity="warning" onClick={refreshSession}>
                            <AlertTitle>Pas op</AlertTitle>
                            De sessie loopt af om {expiresAt.format("HH:mm")}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={expired}
                    >
                        <Alert variant="filled" severity="error" onClick={refreshSession}>
                            <AlertTitle>Error</AlertTitle>
                            De sessie is gestopt. Klik om opnieuw te beginnen.
                        </Alert>
                    </Snackbar>
                </Box>
                <BreakTheGlassDialog/>
            </Container>
        </MuiThemeProvider>
    )
}

export default withStyles(useStyles)(App)
