import dataProvider from '../../../lib/dataProvider'
import { combineItems } from '../../../lib/util'

const MIC_CORRECTION = 0.989
const ASC_CORRECTION = 0.934
const SPSI_CORRECTION = 0.6
const TOLERANCE = -1

/**
 * check if action value lays inbetween min max
 *
 * @param {Object} state - current state[soleResNumber].
 * @param {Object} action - value.
 */
export function validateMinMax (state, action) {
    const elementHeightList = dataProvider.soleResult.elementHeightList

    const side = action.payload.side
    const soleResNumber = action.payload.soleResNumber
    const key = action.payload.key
    const orderType = action.payload.orderType
    const spec = orderType === 'fits' ? action.payload.fits[soleResNumber].fitsData : action.payload.sole[soleResNumber].soleData
    const minVal = elementHeightList[key].min
    const maxVal = elementHeightList[key].max

    if (action.payload.value === '') {
        state[soleResNumber].soleResultErrors[side][key] = ''
        return
    }

    if (key === 'SA15') {
        validateSA15(state, action)
        return
    }

    if (orderType !== 'fits' && spec.block.key === '3D PU Blok') {
        if (key === 'CV' && action.payload.value < 10) {
            state[soleResNumber].soleResultErrors[side][key] = 'Error 502: Voor een 3D PU blok is een CV van minimaal 10mm nodig.'
        } else {
            state[soleResNumber].soleResultErrors[side][key] = ''
        }
    } else if (action.payload.value < minVal || action.payload.value > maxVal) {
        state[soleResNumber].soleResultErrors[side][key] = `Error 502: Vul een waarde tussen ${minVal} en ${maxVal} in.`
    } else {
        state[soleResNumber].soleResultErrors[side][key] = ''
    }
}

/**
 * Make sure that asc diffc is not higher than 8.
 *
 * @param {Object} state - current state[soleResNumber].
 * @param {Object} action - value.
 */
export function validateASCDiff (state, action) {
    const side = action.payload.side
    const soleResNumber = action.payload.soleResNumber
    const key = action.payload.key
    if (action.payload.diagnosis.symmetrical) return

    const ascLeft = state[soleResNumber].soleResultData.left.ASC === '' ? state[soleResNumber].soleResultSuggestion.left.ASC : state[soleResNumber].soleResultData.left.ASC
    const ascRight = state[soleResNumber].soleResultData.right.ASC === '' ? state[soleResNumber].soleResultSuggestion.right.ASC : state[soleResNumber].soleResultData.right.ASC
    switch (side) {
    case 'left':
        if (action.payload.value - ascRight > 8) {
            state[soleResNumber].soleResultErrors[side][
                key
            ] = `Error 503: ASC verschil tussen links en rechts is ${action.payload.value - ascRight} mm. Kies dit verschil alleen als het echt nodig is.`
        }
        break
    case 'right':
        if (action.payload.value - ascLeft > 8) {
            state[soleResNumber].soleResultErrors[side][
                key
            ] = `Error 503: ASC verschil tussen links en rechts is ${action.payload.value - ascLeft} mm. Kies dit verschil alleen als het echt nodig is.`
        }
        break
    }

    return state[soleResNumber].soleResultErrors[side][key]
}

/**
 * Make sure that for FITS the choice for the ASC is 6mm or higher.
 */
export function validateFitsAsc (state, action) {
    const side = action.payload.side
    const soleResNumber = action.payload.soleResNumber
    const key = action.payload.key

    if (action.payload.orderType === 'fits' && dataProvider.getFolder() !== 'fits-comfort' && action.payload.value < 6 && action.payload.value !== '') {
        state[soleResNumber].soleResultErrors[side][key] = 'Error 507: ASC moet bij FITS minimaal 6mm zijn.'
    }
    return state[soleResNumber].soleResultErrors[side][key]
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
export function validatePelotteSuggestion (state, action) {
    const sole = action.payload.sole || undefined
    if (sole === undefined || sole.length === 0) return

    const side = action.payload.side
    const soleResNumber = action.payload.soleResNumber
    const elementHeightList = dataProvider.soleResult.elementHeightList
    const minVal = elementHeightList.pelotte.min
    const maxVal = elementHeightList.pelotte.max
    const pelotte = state[soleResNumber].soleResultData[side].pelotte
    const pelotteSuggestion = state[soleResNumber].soleResultSuggestion[side].pelotte

    if (sole[soleResNumber].soleData.block.key === '3D Sport Printzool') {
        if (pelotte === '' && pelotteSuggestion !== '') {
            if (pelotteSuggestion < minVal || pelotteSuggestion > maxVal) {
                state[soleResNumber].soleResultErrors[side].pelotte = `Error 513: Suggestie valt niet tussen ${minVal} en ${maxVal} in.`
            } else {
                if (pelotteSuggestion % 2 !== 0) {
                    state[soleResNumber].soleResultErrors[side].pelotte = 'Error 514: Pelotte hoogte moet een even nummer zijn'
                }
            }
        }
    }
}

/**
 * Make sure that fasci is filled when mimimal asc is 4mm.
 *
 * @param {Object} state - current state[soleResNumber].
 * @param {Object} action - value.
 */
export function validateFacsiASC (state, action) {
    const side = action.payload.side
    const soleResNumber = action.payload.soleResNumber
    const orderType = action.payload.orderType
    const isFits = orderType === 'fits' && dataProvider.getFolder() !== 'fits-comfort' ? 9 : 4
    const key = action.payload.key
    const ascLeft = state[soleResNumber].soleResultData.left.ASC ? state[soleResNumber].soleResultData.left.ASC : state[soleResNumber].soleResultSuggestion.left.ASC
    const ascRight = state[soleResNumber].soleResultData.right.ASC ? state[soleResNumber].soleResultData.right.ASC : state[soleResNumber].soleResultSuggestion.right.ASC
    switch (key) {
    case 'ASC':
        if (action.payload.value < isFits && state[soleResNumber].soleResultData.left.fasciPad === 'true' && action.payload.side === 'left') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 504: ASC moet minimaal ${isFits}mm zijn voor een Fasci-opvulling`
        }
        if (action.payload.value < isFits && state[soleResNumber].soleResultData.right.fasciPad === 'true' && action.payload.side === 'right') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 504: ASC moet minimaal ${isFits}mm zijn voor een Fasci-opvulling`
        }
        if (action.payload.value < isFits && state[soleResNumber].soleResultData.left.ascPad === 'true' && action.payload.side === 'left') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 504: ASC moet minimaal ${isFits}mm zijn voor een Ascendens-opvulling`
        }
        if (action.payload.value < isFits && state[soleResNumber].soleResultData.right.ascPad === 'true' && action.payload.side === 'right') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 504: ASC moet minimaal ${isFits}mm zijn voor een Ascendens-opvulling`
        }
        break
    case 'fasciPad':
        if (action.payload.value === 'true' && ascLeft < isFits && action.payload.side === 'left') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 504: ASC moet minimaal ${isFits}mm zijn voor een Fasci-opvulling`
        } else if (action.payload.value === 'true' && ascRight < isFits && action.payload.side === 'right') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 504: ASC moet minimaal ${isFits}mm zijn voor een Fasci-opvulling`
        } else {
            state[soleResNumber].soleResultErrors[side][key] = ''
        }
        break
    case 'ascPad':
        if (action.payload.value === 'true' && ascLeft < isFits && action.payload.side === 'left') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 504: ASC moet minimaal ${isFits}mm zijn voor een Ascendens-opvulling`
        } else if (action.payload.value === 'true' && ascRight < isFits && action.payload.side === 'right') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 504: ASC moet minimaal ${isFits}mm zijn voor een Ascendens-opvulling`
        } else {
            state[soleResNumber].soleResultErrors[side][key] = ''
        }
        break
    }
    return state[soleResNumber].soleResultErrors[side][key]
}
/**
 * Ensure that ascendance padding and the fasci padding are not chosen at the same time.
 *
 * @param {Object} state - current state[soleResNumber].
 * @param {Object} action - value.
 */
export function validateAscPadFasciPad (state, action) {
    const side = action.payload.side
    const soleResNumber = action.payload.soleResNumber
    const key = action.payload.key
    const sole = action.payload.sole[soleResNumber].soleData

    switch (key) {
    case 'fasciPad':
        if (action.payload.value === 'true' && state[soleResNumber].soleResultData[side].ascPad === 'true') {
            state[soleResNumber].soleResultErrors[side][key] = 'Error 505: Fasci en ascendens opvullingen kunnen niet tegelijk worden gekozen'
        }

        if (action.payload.orderType === 'insole') {
            if (sole.coverMaterial.includes('Flexline') && state[soleResNumber].soleResultData[side].fasciPad === 'true') {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 506: Bij een fasci of ascendens opvulling kan beter een andere afdek dan flexline gebruikt worden'
            }
        }
        break
    case 'ascPad':
        if (action.payload.value === 'true' && state[soleResNumber].soleResultData[side].fasciPad === 'true') {
            state[soleResNumber].soleResultErrors[side][key] = 'Error 505: Fasci en ascendens opvullingen kunnen niet tegelijk worden gekozen'
        }

        if (action.payload.orderType === 'insole') {
            if (sole.coverMaterial.includes('Flexline') && state[soleResNumber].soleResultData[side].ascPad === 'true') {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 506: Bij een fasci of ascendens opvulling kan beter een andere afdek dan flexline gebruikt worden'
            }
        }
        break
    }

    return state[soleResNumber].soleResultErrors[side][key]
}

/**
 * Ensures insole element SA15 matches its description.
 *
 * @param {Object} state - current state[soleResNumber].
 * @param {Object} action - value.
 */
export function validateSA15 (state, action) {
    const side = action.payload.side
    const soleResNumber = action.payload.soleResNumber
    const key = action.payload.key

    const orderType = action.payload.orderType
    const sole = action.payload.sole[soleResNumber].soleData

    if (sole.block.key === '3D Sport Printzool' && orderType !== 'fits') {
        if (action.payload.value !== 3 && action.payload.value !== 0 && !isNaN(action.payload.value)) {
            state[soleResNumber].soleResultErrors[side][key] = 'Error 510: Er kan geen andere keuze gemaakt worden voor dit element dan 3 of 0'
        } else {
            state[soleResNumber].soleResultErrors[side][key] = ''
        }
    }
}

/**
 * Ensures insole Elements that can't be chosen together won't be chosen together..
 *
 * @param {Object} state - current state[soleResNumber].
 * @param {Object} action - value.
 */
export function validateConflictsInsoleElements (state, action) {
    const side = action.payload.side
    const soleResNumber = action.payload.soleResNumber
    const key = action.payload.key

    const orderType = action.payload.orderType
    const sole = action.payload.sole[soleResNumber].soleData

    switch (key) {
    case 'pelotte':
        if (sole.block.key === '3D Sport Printzool' && orderType !== 'fits') {
            if (action.payload.value % 2 !== 0) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 511: Pelotte moet een even nummer zijn'
            }
        }
        break
    case 'PPSI':
        if (sole.block.key === '3D Sport Printzool' && orderType !== 'fits') {
            if (action.payload.value % 2 !== 0) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 511: PPSI moet een even nummer zijn'
            }
            if (action.payload.value >= 1 && state[soleResNumber].soleResultData.left.ASC >= 4) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 512: Wanneer ASC groter is dan 4 is het niet mogelijk PPSI te selecteren.'
            }
            if (action.payload.value >= 1 && state[soleResNumber].soleResultData.right.ASC >= 4) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 512: Wanneer ASC groter is dan 4 is het niet mogelijk PPSI te selecteren.'
            }
        }
        if (action.payload.value >= 1 && state[soleResNumber].soleResultData.left.SPSI >= 1 && side === 'left') {
            state[soleResNumber].soleResultErrors[side][key] = 'Error 506: PPSI en SPSI kunnen niet tegelijk worden gekozen'
        }
        if (action.payload.value >= 1 && state[soleResNumber].soleResultData.right.SPSI >= 1 && side === 'right') {
            state[soleResNumber].soleResultErrors[side][key] = 'Error 506: PPSI en SPSI kunnen niet tegelijk worden gekozen'
        }
        break
    case 'PT':
        if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.left.SPSI >= 1 || state[soleResNumber].soleResultData.left.MFW >= 1 || state[soleResNumber].soleResultData.left.SA1 >= 1) && side === 'left') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 506: PT ${state[soleResNumber].soleResultData.left.SPSI >= 1 ? 'en SPSI ' : ''}${state[soleResNumber].soleResultData.left.MFW >= 1 ? 'en MVW ' : ''}${state[soleResNumber].soleResultData.left.SA1 >= 1 ? 'en SA1 ' : ''}kunnen niet tegelijk worden gekozen`
        }
        if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.right.SPSI >= 1 || state[soleResNumber].soleResultData.right.MFW >= 1 || state[soleResNumber].soleResultData.right.SA1 >= 1) && side === 'right') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 506: PT ${state[soleResNumber].soleResultData.right.SPSI >= 1 ? 'en SPSI ' : ''}${state[soleResNumber].soleResultData.right.MFW >= 1 ? 'en MVW ' : ''}${state[soleResNumber].soleResultData.right.SA1 >= 1 ? 'en SA1 ' : ''}kunnen niet tegelijk worden gekozen`
        }
        break
    case 'SA15':
        if (sole.block.key === '3D Sport Printzool' && orderType !== 'fits') {
            if (action.payload.value >= 1 && (state[soleResNumber].soleResultData[side].MFW === 'true')) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 509: MVW en SA 1-5 kunnen niet tegelijk worden gekozen'
            }
        } else {
            if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.left.SA25 >= 1 || state[soleResNumber].soleResultData.left.SA1 >= 1) && side === 'left') {
                state[soleResNumber].soleResultErrors[side][key] = `Error 506: SA15 ${state[soleResNumber].soleResultData.left.SA25 >= 1 ? 'en SA25 ' : ''}${state[soleResNumber].soleResultData.right.SA1 >= 1 ? 'en SA1 ' : ''}kunnen niet tegelijk worden gekozen`
            }
            if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.right.SA25 >= 1 || state[soleResNumber].soleResultData.right.SA1 >= 1) && side === 'right') {
                state[soleResNumber].soleResultErrors[side][key] = `Error 506: SA15 ${state[soleResNumber].soleResultData.right.SA25 >= 1 ? 'en SA25 ' : ''}${state[soleResNumber].soleResultData.right.SA1 >= 1 ? 'en SA1 ' : ''}kunnen niet tegelijk worden gekozen`
            }
        }
        break
    case 'SPSI':
        if (sole.block.key === '3D Sport Printzool' && orderType !== 'fits') {
            if (action.payload.value % 2 !== 0) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 511: PPSI moet een even nummer zijn.'
            }
            if (action.payload.value >= 1 && state[soleResNumber].soleResultData.left.ASC >= 4) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 512: Wanneer ASC groter is dan 4 is het niet mogelijk SPSI te selecteren.'
            }
            if (action.payload.value >= 1 && state[soleResNumber].soleResultData.right.ASC >= 4) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 512: Wanneer ASC groter is dan 4 is het niet mogelijk SPSI te selecteren.'
            }
        }
        if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.left.PPSI >= 1 || state[soleResNumber].soleResultData.left.PT >= 1) && side === 'left') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 506: SPSI ${state[soleResNumber].soleResultData.left.PPSI >= 1 ? 'en PPSI ' : ''}${state[soleResNumber].soleResultData.left.PT >= 1 ? 'en PT ' : ''}kunnen niet tegelijk worden gekozen`
        }
        if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.right.PPSI >= 1 || state[soleResNumber].soleResultData.right.PT >= 1) && side === 'right') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 506: SPSI ${state[soleResNumber].soleResultData.right.PPSI >= 1 ? 'en PPSI ' : ''}${state[soleResNumber].soleResultData.right.PT >= 1 ? 'en PT ' : ''}kunnen niet tegelijk worden gekozen`
        }
        break
    case 'MFW':
        if (sole.block.key === '3D Sport Printzool' && orderType !== 'fits') {
            if ((action.payload.value === 'true' && (state[soleResNumber].soleResultData[side].LFW === 'true'))) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 509: MVW en LFW kunnen niet tegelijk worden gekozen'
            }
            if (action.payload.value === 'true' && (state[soleResNumber].soleResultData[side].SA15 >= 1)) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 509: MVW en SA 1-5 kunnen niet tegelijk worden gekozen'
            }
        } else {
            if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.left.LFW >= 1 || state[soleResNumber].soleResultData.left.PT >= 1 || state[soleResNumber].soleResultData.left.SA25 >= 1) && side === 'left') {
                state[soleResNumber].soleResultErrors[side][key] = `Error 506: MVW ${state[soleResNumber].soleResultData.left.LFW >= 1 ? 'en LFW ' : ''}${state[soleResNumber].soleResultData.left.PT >= 1 ? 'en PT ' : ''}${state[soleResNumber].soleResultData.left.SA25 >= 1 ? 'en SA25 ' : ''}kunnen niet tegelijk worden gekozen`
            }
            if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.right.LFW >= 1 || state[soleResNumber].soleResultData.right.PT >= 1 || state[soleResNumber].soleResultData.right.SA25 >= 1) && side === 'right') {
                state[soleResNumber].soleResultErrors[side][key] = `Error 506: MVW ${state[soleResNumber].soleResultData.right.LFW >= 1 ? 'en LFW ' : ''}${state[soleResNumber].soleResultData.right.PT >= 1 ? 'en PT ' : ''}${state[soleResNumber].soleResultData.right.SA25 >= 1 ? 'en SA25 ' : ''}kunnen niet tegelijk worden gekozen`
            }
        }
        break
    case 'LFW':
        if (sole.block.key === '3D Sport Printzool' && orderType !== 'fits') {
            if ((action.payload.value === 'true' && (state[soleResNumber].soleResultData[side].MFW === 'true'))) {
                state[soleResNumber].soleResultErrors[side][key] = 'Error 509: MVW en LFW kunnen niet tegelijk worden gekozen'
            }
        } else {
            if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.left.MFW >= 1 || state[soleResNumber].soleResultData.left.SA1 >= 1) && side === 'left') {
                state[soleResNumber].soleResultErrors[side][key] = `Error 506: LFW ${state[soleResNumber].soleResultData.left.MFW >= 1 ? 'en MVW ' : ''}${state[soleResNumber].soleResultData.left.SA1 >= 1 ? 'en SA1 ' : ''}kunnen niet tegelijk worden gekozen`
            }
            if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.right.MFW >= 1 || state[soleResNumber].soleResultData.right.SA1 >= 1) && side === 'right') {
                state[soleResNumber].soleResultErrors[side][key] = `Error 506: LFW ${state[soleResNumber].soleResultData.right.MFW >= 1 ? 'en MVW ' : ''}${state[soleResNumber].soleResultData.right.SA1 >= 1 ? 'en SA1 ' : ''}kunnen niet tegelijk worden gekozen`
            }
        }
        break
    case 'SA25':
        if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.left.MFW >= 1 || state[soleResNumber].soleResultData.left.SA1 >= 1 || state[soleResNumber].soleResultData.left.SA15 >= 1) && side === 'left') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 506: SA25 ${state[soleResNumber].soleResultData.left.MFW >= 1 ? 'en MVW ' : ''}${state[soleResNumber].soleResultData.left.SA1 >= 1 ? 'en SA1 ' : ''}${state[soleResNumber].soleResultData.left.SA15 >= 1 ? 'en SA15 ' : ''}kunnen niet tegelijk worden gekozen`
        }
        if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.right.MFW >= 1 || state[soleResNumber].soleResultData.right.SA1 >= 1 || state[soleResNumber].soleResultData.right.SA15 >= 1) && side === 'right') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 506: SA25 ${state[soleResNumber].soleResultData.right.MFW >= 1 ? 'en MVW ' : ''}${state[soleResNumber].soleResultData.right.SA1 >= 1 ? 'en SA1 ' : ''}${state[soleResNumber].soleResultData.right.SA15 >= 1 ? 'en SA15 ' : ''}kunnen niet tegelijk worden gekozen`
        }
        break
    case 'SA1':
        if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.left.LFW >= 1 || state[soleResNumber].soleResultData.left.PT >= 1 || state[soleResNumber].soleResultData.left.SA25 >= 1 || state[soleResNumber].soleResultData.left.SA15 >= 1) && side === 'left') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 506: SA1 ${state[soleResNumber].soleResultData.left.LFW >= 1 ? 'en LFW ' : ''}${state[soleResNumber].soleResultData.left.PT >= 1 ? 'en PT ' : ''}${state[soleResNumber].soleResultData.left.SA25 >= 1 ? 'en SA25 ' : ''}${state[soleResNumber].soleResultData.left.SA15 >= 1 ? 'en SA15 ' : ''}kunnen niet tegelijk worden gekozen`
        }
        if (action.payload.value >= 1 && (state[soleResNumber].soleResultData.right.LFW >= 1 || state[soleResNumber].soleResultData.right.PT >= 1 || state[soleResNumber].soleResultData.right.SA25 >= 1 || state[soleResNumber].soleResultData.right.SA15 >= 1) && side === 'right') {
            state[soleResNumber].soleResultErrors[side][key] = `Error 506: SA1 ${state[soleResNumber].soleResultData.right.LFW >= 1 ? 'en LFW ' : ''}${state[soleResNumber].soleResultData.right.PT >= 1 ? 'en PT ' : ''}${state[soleResNumber].soleResultData.right.SA25 >= 1 ? 'en SA25 ' : ''}${state[soleResNumber].soleResultData.right.SA15 >= 1 ? 'en SA15 ' : ''}kunnen niet tegelijk worden gekozen`
        }
        break
    default:
        break
    }
}

/**
 * Checks if there are no disallowed elements for a Neskrid sole
 */
export function validateNeskridCoverMaterial (state, action) {
    const soleResNumber = action.payload.soleResNumber
    const sole = action.payload.sole[soleResNumber].soleData
    const key = action.payload.key
    const side = action.payload.side

    if (sole.coverMaterial === 'Multicover 4Allbrands (Neskrid)' && action.payload.value >= 1) {
        state[soleResNumber].soleResultErrors[side][key] = 'Error 508: Voor Neskrid werkschoenen mag dit element niet gekozen worden.'
    }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
export function validateMic (state, action) {
    const side = action.payload.side
    const soleResNumber = action.payload.soleResNumber
    const key = action.payload.key

    const orderType = action.payload.orderType
    const sole = action.payload.sole[soleResNumber].soleData

    if (sole.block.key === '3D Sport Printzool' && orderType !== 'fits') {
        if (action.payload.value % 2 !== 0) {
            state[soleResNumber].soleResultErrors[side][key] = 'Error 511: MIC moet een even nummer zijn'
        }
    }
}

/**
 * Infamous tafelbergCheck.
 *
 * @param {Object} state - current state[soleResNumber].
 * @param {Object} action - value.
 */
export function tafelbergCheck (state, action) {
    const side = action.payload.side
    const diagnosis = action.payload.diagnosis
    const orderType = action.payload.orderType
    const soleResNumber = action.payload.soleResNumber
    const suggestion = state[soleResNumber].soleResultSuggestion[side]
    const suggestionLeft = state[soleResNumber].soleResultSuggestion.left
    const spec = orderType === 'fits' ? action.payload.fits[soleResNumber].fitsData : action.payload.sole[soleResNumber].soleData
    const fitsSoleThickness = 5
    const dataFolder = dataProvider.getFolder()
    const millSize = spec.millingSize
    const soleThicknessLeft = (orderType === 'fits' && dataFolder !== 'fits-comfort') ? fitsSoleThickness : spec.totalLeftSoleThickness
    const soleThicknessRight = (orderType === 'fits' && dataFolder !== 'fits-comfort') ? fitsSoleThickness : spec.totalRightSoleThickness

    if (orderType !== 'fits' && spec.block.key === '3D PU Blok') {
        state[soleResNumber].soleResultErrors.left.tafelberg = false
        state[soleResNumber].soleResultErrors.right.tafelberg = false
        state[soleResNumber].soleResultAccordion.left.validate = true
        state[soleResNumber].soleResultAccordion.right.validate = true
        return
    }

    if (spec.displayRightSole && diagnosis.symmetrical) {
        const leftSoleThickness = Number.parseFloat(soleThicknessLeft)
        const rightSoleThickness = Number.parseFloat(soleThicknessRight)

        // get left and right mic and asc
        const stateMic = state[soleResNumber].soleResultData.left.MIC === '' ? 0 : state[soleResNumber].soleResultData.left.MIC
        const stateAsc = state[soleResNumber].soleResultData.left.ASC === '' ? 0 : state[soleResNumber].soleResultData.left.ASC
        const stateSPSI = state[soleResNumber].soleResultData.left.SPSI === '' ? 0 : state[soleResNumber].soleResultData.left.SPSI

        const mic = stateMic !== 0 && stateMic !== null ? Number.parseFloat(stateMic) : suggestionLeft.MIC !== undefined ? Number.parseFloat(suggestionLeft.MIC) : 0
        const asc = stateAsc !== 0 && stateAsc !== null ? Number.parseFloat(stateAsc) : suggestionLeft.ASC !== undefined ? Number.parseFloat(suggestionLeft.ASC) : 0
        const spsi = stateSPSI !== 0 && stateSPSI !== null ? Number.parseFloat(stateSPSI) : suggestionLeft.SPSI !== undefined ? Number.parseFloat(suggestionLeft.SPSI) : 0

        // corrections left and right
        const micVal = mic * MIC_CORRECTION
        const ascVal = asc * ASC_CORRECTION
        const spsiVal = spsi * SPSI_CORRECTION

        const leftValue = leftSoleThickness + micVal + ascVal + spsiVal + TOLERANCE
        const rightValue = rightSoleThickness + micVal + ascVal + spsiVal + TOLERANCE

        state[soleResNumber].soleResultData.left.tafelberg = (leftValue - millSize).toFixed(1)
        state[soleResNumber].soleResultData.right.tafelberg = (rightValue - millSize).toFixed(1)

        if (orderType === 'fits' ? (spec.block.includes('38mm') && dataFolder !== 'fits-comfort') : (spec.block.millingSize === 38 || spec.block.key.includes('3D PU'))) {
            const extHeelLeft = Math.max(Math.ceil(parseFloat((leftValue - millSize).toFixed(1))), 0)
            const extHeelRight = Math.max(Math.ceil(parseFloat((rightValue - millSize).toFixed(1))), 0)
            state[soleResNumber].soleResultData.left.externalHeelLift = extHeelLeft
            state[soleResNumber].soleResultData.right.externalHeelLift = extHeelRight

            // set/resolve errors
            state[soleResNumber].soleResultErrors.left.externalHeelLift = extHeelLeft > 15
            state[soleResNumber].soleResultErrors.right.externalHeelLift = extHeelRight > 15
        }

        if ((leftValue - millSize).toFixed(1) === 0.0 && (rightValue - millSize).toFixed(1) === 0.0) {
            state[soleResNumber].soleResultErrors.left.tafelberg = false
        } else {
            state[soleResNumber].soleResultErrors.left.tafelberg = (leftValue > millSize)
            state[soleResNumber].soleResultErrors.right.tafelberg = (rightValue > millSize)
        }
        state[soleResNumber].soleResultAccordion.left.validate = !(leftValue > millSize)
        state[soleResNumber].soleResultAccordion.right.validate = !(rightValue > millSize)

        return !(leftValue > millSize)
    } else {
        let soleThickness = 0
        if (side === 'left') {
            soleThickness = Number.parseFloat(soleThicknessLeft)
        } else if (side === 'right') {
            soleThickness = Number.parseFloat(soleThicknessRight)
        }

        const stateMic = state[soleResNumber].soleResultData[side].MIC === '' ? 0 : state[soleResNumber].soleResultData[side].MIC
        const stateAsc = state[soleResNumber].soleResultData[side].ASC === '' ? 0 : state[soleResNumber].soleResultData[side].ASC
        const stateSPSI = state[soleResNumber].soleResultData[side].SPSI === '' ? 0 : state[soleResNumber].soleResultData[side].SPSI
        const mic = stateMic !== 0 && stateMic !== null ? Number.parseFloat(stateMic) : (suggestion.MIC !== undefined && suggestion.MIC !== '') ? Number.parseFloat(suggestion.MIC) : 0
        const asc = stateAsc !== 0 && stateAsc !== null ? Number.parseFloat(stateAsc) : (suggestion.ASC !== undefined && suggestion.ASC !== '') ? Number.parseFloat(suggestion.ASC) : 0
        const spsi = stateSPSI !== 0 && stateSPSI !== null ? Number.parseFloat(stateSPSI) : (suggestion.SPSI !== undefined && suggestion.SPSI !== '') ? Number.parseFloat(suggestion.SPSI) : 0

        const micVal = mic * MIC_CORRECTION
        const ascVal = asc * ASC_CORRECTION
        const spsiVal = spsi * SPSI_CORRECTION
        const value = soleThickness + micVal + ascVal + spsiVal + TOLERANCE

        state[soleResNumber].soleResultData[side].tafelberg = (value - millSize).toFixed(1)
        if (orderType === 'fits' ? (spec.block.includes('38mm') && dataFolder !== 'fits-comfort') : (spec.block.millingSize === 38 || spec.block.key.includes('3D PU'))) {
            // Always calculate left and right externalheellift.s
            const extHeel = Math.max(Math.ceil(parseFloat((value - millSize).toFixed(1))), 0)
            state[soleResNumber].soleResultData[side].externalHeelLift = extHeel
            state[soleResNumber].soleResultErrors[side].externalHeelLift = extHeel > 15
        }

        if ((value - millSize).toFixed(1) === 0.0) {
            state[soleResNumber].soleResultErrors[side].tafelberg = false
        } else {
            state[soleResNumber].soleResultErrors[side].tafelberg = value > millSize
        }
        state[soleResNumber].soleResultAccordion[side].validate = !(value > millSize)
        return (value - millSize).toFixed(1)
    }
}

/**
 *
 * @param {*} state
 * @param {*} value
 * @param {*} side
 */
export function validateRelief (state, value, soleResNumber, action) {
    const side = action.payload.side
    const combinedItemsResult = combineItems(value)

    state[soleResNumber].soleResultErrors.message = state[soleResNumber].soleResultErrors.message.filter(e => e.substring(0, 9) !== 'Error 515')
    state[soleResNumber].soleResultErrors[side].relief = ''

    combinedItemsResult.forEach((option, i) => {
        const optionPos = parseInt(option.replace(/[A-Za-z]/g, ''))

        if (combinedItemsResult[i + 1] === undefined) return
        const nextOptionPos = parseInt(combinedItemsResult[i + 1].replace(/[A-Za-z]/g, ''))
        const nextOptionFirstChar = parseInt(combinedItemsResult[i + 1].charAt(2))

        if (optionPos + 1 === nextOptionPos || optionPos + 1 === nextOptionFirstChar) {
            state[soleResNumber].soleResultErrors.message.push('Error 515: Uitsparing combinatie niet mogelijk')
            state[soleResNumber].soleResultErrors[side].relief = 'Error 515'
            return
        }

        const optionLastChar = parseInt(option.charAt(option.length - 1))

        if (optionLastChar + 1 === nextOptionPos) {
            state[soleResNumber].soleResultErrors.message.push('Error 515: Uitsparing combinatie niet mogelijk')
            state[soleResNumber].soleResultErrors[side].relief = 'Error 515'
        }
    })
}

/**
 * Make sure that tafelbergerror is not active.
 *
 * @param {Object} state - current state[soleResNumber].
 * @param {Object} side - current side.
 */
export function validateAllFields (state, side, soleResNumber) {
    const soleData = state[soleResNumber].soleResultData[side]
    let textSuggestionIsSet = true
    let containsError = false
    const allRows = dataProvider.soleResult.allRows

    allRows.forEach(key => {
        const input = soleData[key]
        const suggestion = state[soleResNumber].soleResultSuggestion[side][key]
        const errorMessage = state[soleResNumber].soleResultErrors[side][key]

        // if suggestion is string, that is filled.
        if (suggestion !== null && !Number.isInteger(suggestion) && suggestion.length > 3 && key !== 'fasciPad' && key !== 'ascPad') {
            if (input === '') {
                textSuggestionIsSet = false
                state[soleResNumber].soleResultErrors[side][key] = 'Error 501: Vul een getal in.'
            } else if (!state[soleResNumber].soleResultErrors[side][key].includes('508')) {
                state[soleResNumber].soleResultErrors[side][key] = ''
            }
        }

        if (errorMessage.length > 3) {
            containsError = true
        }
    })

    state[soleResNumber].soleResultAccordion[side].validate = (
        !state[soleResNumber].soleResultErrors[side].tafelberg &&
        !state[soleResNumber].soleResultErrors[side].externalHeelLift &&
        !state[soleResNumber].soleResultErrors[side].relief &&
        !containsError &&
        textSuggestionIsSet
    )
}
