import React, { useEffect, useState } from 'react'
import {Grid, Typography, Button, Paper, Select, MenuItem, Tooltip, Alert} from '@mui/material'
import clsx from 'clsx'
// import WenderLogoWit from '../../assets/wenderlogo-wit.svg'
import ZoolLogoWit from '../../assets/zoolLogo-wit.svg'
import FitsLogoWit from '../../assets/fitslogo-wit.svg'
import useStyles from './style'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import api from '../../lib/api'
import { patientData, setError, setOrderType } from '../../store/reducers/general'
import { useDispatch, useSelector } from 'react-redux'
import UserService from '../../lib/userService'

import { initialState as initDiagnose } from '../../store/reducers/diagnosis/initialState'
import { initialState as initSoleRes } from '../../store/reducers/soleResult/initialState'
import { initialState as initSole } from '../../store/reducers/soleSpecification/initialState'

import { resetSole, soleData } from '../../store/reducers/soleSpecification'
import { resetSoleResult } from '../../store/reducers/soleResult'
import { resetDiagnosis } from '../../store/reducers/diagnosis'
import { setPreviousOrders, changeBackend, changeDataProvider, setBreakTheGlass } from '../../store/reducers/other'
import { withStyles } from '@mui/styles'
import moment from 'moment'
/** @import {SemVer} from '../../version */
import { getVersionString, getVersion, parseVersionString } from '../../lib/version'

const CHANGELOG_URL = "https://www.wewolk.nl/groups/61"
const UPDATE_NOTIFICATION_DISMISSED_KEY = "slim_update_notification_dismissed"

/**
 * Nullifies the patch, prerelease and build metadata
 * @param {SemVer} version
 * @returns {SemVer} The version with patch number set to zero and prerelease cleared
 */
function nullifyPreRelease(version) {
    const copy = version.clone()
    copy.prerelease = undefined
    copy.buildMetadata = undefined
    return copy
}



const HomePage = ({ classes }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [environmnents, setEnvironments] = useState([])
    const [selectedBackend, setBackend] = useState('geen')
    const [showSwitch, setSwitch] = useState(false)
    const [showUpdate, setShowUpdate] = useState(shouldShowUpdateNotification)
    const [queryParameters] = useSearchParams()

    const { number, environment } = useParams()
    const other = useSelector((state) => state.other)
    const version = getVersionString()

    /**
     * get the authenticated podiatrist's name and email
     * (activated only in production)
     */
    useEffect(() => {
        backendInitialization()

        // save the patient number when given.
        if (number !== undefined) dispatch(patientData({ key: 'number', value: number }))

        const fetchTherapists = () => {
            api.therapists((rsp) => {
                dispatch(patientData({
                    key: 'therapist',
                    value: {
                        name: rsp.name,
                        email: rsp.email
                    }
                }),
                api.setSharepointInformation(rsp.microsoftToken, queryParameters, number)
                )
            }, (error) => { dispatch(setError(error)) })
        }

        fetchTherapists()
        dispatch(setOrderType(''))
        dispatch(resetDiagnosis(initDiagnose))
        dispatch(resetSole(initSole))
        dispatch(resetSoleResult(initSoleRes))
        if (number === undefined) return
        api.patients(number,
            (data) => {
                const bday = moment(data.birthDate).add(2, 'h')
                dispatch(patientData({ key: 'number', value: number }))
                dispatch(patientData({ key: 'lastName', value: data.lastName }))
                dispatch(patientData({ key: 'birthdate', value: bday }))
                dispatch(patientData({ key: 'gender', value: data.sex.toLowerCase() }))
                dispatch(patientData({ key: 'prefferedSupport', value: data.sex.toLowerCase() }))
                dispatch(patientData({ key: 'id', value: `${data.id}` }))
                dispatch(patientData({ key: 'initials', value: `${data.initials}` }))

                if (data.footLengthLeft !== 0) {
                    for (let soleSpecNumber = 0; soleSpecNumber < 3; soleSpecNumber++) {
                        dispatch(soleData({ key: 'footSizeLeft', value: data.footLengthLeft, soleSpecNumber }))
                        dispatch(soleData({ key: 'footSizeRight', value: data.footLengthRight, soleSpecNumber }))
                    }
                }
                // api.checkAccess(data.id, () => {
                api.getOrder(data.id, (data) => {
                    const parsedData = []
                    data.forEach(element => {
                        parsedData.push(JSON.parse(element))
                    })
                    const orders = parsedData.sort((a, b) => b.senddate - a.senddate)
                    dispatch(setPreviousOrders(orders))
                }, (error) => {
                    if(error.response.status === 403){
                        dispatch(setBreakTheGlass(true))
                    } else {
                        dispatch(setError(error))
                    }
                })
                // }, () => {dispatch(setBreakTheGlass(true))})
            }, (error) => {
                if(error.response.status === 403){
                    dispatch(setBreakTheGlass(true))
                } else {
                    dispatch(setError(error))
                }
            })
    }, [])

    /**
     * Continue to fits page.
     */
    function toFits () {
        navigate('/fits')
        dispatch(setOrderType('fits'))
    }

    /**
     * Continue to insole page.
     */
    function toInsoles () {
        navigate('/insole')
        dispatch(setOrderType('insole'))
    }

    /**
     * Get the selected backend on start.
     */
    function backendInitialization () {
        const env = UserService.getEnvironments()
        let envSet = false
        setEnvironments(env)

        if (env.length === 1) {
            dispatch(changeBackend(env[0]))
            dispatch(changeDataProvider(env[0].key))

            api.setBackend(env[0].value)
            localStorage.setItem('environment', JSON.stringify(env[0]))
        }

        const redirectURL = queryParameters.get('url')

        if (redirectURL !== null) {
            env.forEach(element => {
                if (element.value === `https://${redirectURL}`) {
                    setBackend(element)
                    dispatch(changeBackend(element))
                    api.setBackend(element.value)
                    localStorage.setItem('environment', JSON.stringify(element))
                    envSet = true
                }
            })
            if (envSet) return
        }

        if (environment !== undefined) {
            env.forEach(element => {
                if (element.key === environment) {
                    setBackend(element)

                    dispatch(changeBackend(element))
                    dispatch(changeDataProvider(element.key))

                    api.setBackend(element.value)
                    localStorage.setItem('environment', JSON.stringify(element))
                    envSet = true
                }
            })
            if (envSet) return
        }

        if (localStorage.getItem('environment') !== null) {
            // const local = localStorage.getItem('environment')
            // const inList = env.find(environment => environment === local)
            // // check if the locastorage item is in the list of environments
            // if (inList) {
            const backend = JSON.parse(localStorage.getItem('environment'))
            dispatch(changeBackend(backend))
            dispatch(changeDataProvider(backend.key))

            api.setBackend(backend.value)
            return
            // } else {
            //     setSwitch(true)
            // }
        }
        if (other.backend.key === '' && env.length > 1) {
            setSwitch(true)
        }
    }

    /**
     * Change the selected backend
     *
     * @param {String} backend - selected backendprovider
     */
    function updateBackend (backend) {
        setBackend(backend)

        dispatch(changeBackend(backend))
        dispatch(changeDataProvider(backend.key))

        api.setBackend(backend.value)
        localStorage.setItem('environment', JSON.stringify(backend))

        setTimeout(() => {
            setSwitch(false)
            setBackend('geen')
        }, 500)
    }

    /**
     * open mail function
     */
    function openMail () {
        window.location.replace('mailto:info@pltproducts.nl&subject=Missende omgeving')
    }


    /**
     * @returns {boolean} True if the update notification should be shown
     */
    function shouldShowUpdateNotification() {
        const lastShownVersionString = window.localStorage.getItem(UPDATE_NOTIFICATION_DISMISSED_KEY)
        if (lastShownVersionString === null) {
            return false
        }

        const currentVersion = nullifyPreRelease(getVersion())
        const lastShownVersion = parseVersionString(lastShownVersionString)
        return lastShownVersion.compareTo(currentVersion) === -1
    }

    /**
     * Marks the update as shown
     */
    function markUpdateShown() {
        const currentMinorVersion = nullifyPreRelease(getVersion())
        window.localStorage.setItem(UPDATE_NOTIFICATION_DISMISSED_KEY, currentMinorVersion.toString())
    }

    useEffect(() => {
        if (!showUpdate) {
            markUpdateShown()
        }
    }, [showUpdate])

    return (
        showSwitch
            ? <Grid justifyContent="center" alignItems="center" container>
                <Grid item xs={8}>
                    <Paper>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Typography variant="h4">Kies een omgeving</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Select
                                        value={selectedBackend}
                                        onChange={event => updateBackend(event.target.value)}
                                    >
                                        <MenuItem disabled key={-1} value={'geen'}>Geen omgeving geselecteerd</MenuItem>
                                        {
                                            environmnents.map((order, index) =>
                                                <MenuItem key={index} value={order}>{order.key}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} spacing={5}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Tooltip title="Klik om te mailen naar: info@pltproducts.nl">
                                        <Button variant='outlined' onClick={() => openMail()}>Help, ik mis een omgeving</Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            {/** SPACING */}
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center"></Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            : <Grid justifyContent="center" alignItems="center" container spacing={5}>
                <Grid item xs={4} onClick={toInsoles}>
                    <Grid
                        container
                        className={clsx([classes.paper, classes.wenderPaper])}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img className={classes.svgPaper} src={ZoolLogoWit} alt="Wender Logo" />
                        <Typography variant="h6" className={classes.paperText}>Podotherapeutische zolen</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={4} onClick={toFits}>
                    <Grid
                        container
                        className={clsx([classes.paper, classes.fitsPaper])}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img className={classes.svgPaper} src={FitsLogoWit} alt="Fits Logo" />
                        <Typography variant="h6" className={classes.paperText}>FITS Custom</Typography>
                        <Typography variant="p" className={classes.paperText}>Slippers/ Sandalen</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.version} justifyContent="center">
                    <Button
                        variant="contained"
                        color='primary'
                        className={classes.backButton}
                        onClick={() => setSwitch(true)}
                    >Wissel omgeving: {other.backend.key}</Button>
                </Grid>
                { showUpdate
                    ? <Grid item xs={8} justifyContent='center'>
                        <Alert
                            className={classes.shiny}
                            onClose={() => setShowUpdate(false)}
                            severity='info'>
                            {/* Append the #v={version} parameter so the link becomes blue with each new version number */}
                            SLIM is bijgewerkt naar versie {version}! <a href={CHANGELOG_URL + "#v=" + version} target='_blank' rel='noreferrer'>Lees hier wat er nieuw en veranderd is</a>.
                        </Alert>
                    </Grid>
                    : <Grid item className={classes.version} justifyContent="center">
                        <Typography className={classes.versionText} variant="caption" align='center'>v{version}</Typography>
                    </Grid>
                }
            </Grid>
    )
}

export default withStyles(useStyles)(HomePage)
