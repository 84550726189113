/**
 * Validate if fields are not empty.
*/
export function validateAllFields (state, action) {
    const messages = state.additionErrors.message
    const index = messages.indexOf('Error 602: Zoollogistiek kan de aanvulling niet toevoegen als de zolen naar een locatie verstuurd worden. Dit kan alleen als je ervoor kiest om de zolen naar de patiënt te laten sturen.')
    state.additionErrors.message = messages.splice(index + 1, 1)

    const receiveLocation = action.payload.value.general.patientData.receiveLocation
    if (receiveLocation.id !== -1 && receiveLocation.id !== -12) {
        const addition = state.additionData.addition[0]
        if (addition !== undefined && addition.name !== '' && addition.location !== '' && addition.forSoles.length > 0) {
            // state.additionData.addition.forEach(element => {
            //     if (element.name === 'ASC EVA Camel 5mm Onder, los bijleveren' || element.name === 'ASC EVA Camel 10mm Onder, los bijleveren') {
            //         state.additionErrors.message.push('Error 602: Zoollogistiek kan de aanvulling niet toevoegen als de zolen naar een locatie verstuurd worden. Dit kan alleen als je ervoor kiest om de zolen naar de patiënt te laten sturen.')
            //     }
            // })
        }
    }
    state.additionAccordion.validate = !checkEmptyFields(state)
}

/**
 * Check if the fields of soleAddition are empty.
 */
function checkEmptyFields (state) {
    const logistic = state.additionData.addition
    let notFilled = false
    logistic.forEach((addition, i) => {
        if (i === 0) {
            if (addition.name === '' && addition.location === '' && addition.forSoles.length === 0) {
                notFilled = false
            } else if (((addition.name !== '' || addition.name !== null) && addition.location === '' && addition.forSoles.length === 0) ||
                ((addition.name !== '' || addition.name !== null) && addition.location !== '' && addition.forSoles.length === 0) ||
                ((addition.name !== '' || addition.name !== null) && addition.location === '' && addition.forSoles.length !== 0) ||
                ((addition.name === '' || addition.name === null) && addition.location !== '' && addition.forSoles.length === 0) ||
                ((addition.name === '' || addition.name === null) && addition.location !== '' && addition.forSoles.length !== 0) ||
                ((addition.name === '' || addition.name === null) && addition.location === '' && addition.forSoles.length !== 0)) {
                notFilled = true
            }
        } else if ((addition.name === '' || addition.name === null) || addition.location === '' || addition.forSoles.length === 0) {
            notFilled = true
        }
    })
    return state.additionErrors.message.length !== 0 || notFilled
}
