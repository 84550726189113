import moment from 'moment'

/**
 * check patient number
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateNumber (state, value) {
    const message = 'Error 201: Vul een patiëntnummer in'
    if (value === '') {
        state.generalErrors.message.push(message)
        state.generalErrors.number = true
    } else {
        state.generalErrors.number = false
        state.generalErrors.message = state.generalErrors.message.filter(e => e !== message)
    }
}

/**
 * check lastname
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateLastName (state, value) {
    const message = 'Error 203: Vul een achternaam in'
    if (value === '') {
        state.generalErrors.message.push(message)
        state.generalErrors.lastName = true
    } else {
        state.generalErrors.lastName = false
        state.generalErrors.message = state.generalErrors.message.filter(e => e !== message)
    }
}

/**
 * check gender
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateGender (state, value) {
    if (value === '') {
        state.generalErrors.gender = true
    } else {
        state.generalErrors.display = false
        state.generalErrors.gender = false
    }
}

/**
 * check birthdate
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateBirthdate (state, value) {
    const now = moment()
    const message = 'Error 202: Vul een correcte geboortedatum van de patiënt in (geen toekomstige datum)'
    if (now < value) {
        state.generalErrors.message.push(message)
        state.generalErrors.birthdate = true
    } else {
        state.generalErrors.birthdate = false
        state.generalErrors.message = state.generalErrors.message.filter(e => e !== message)
    }
}

/**
 * check fittinglocation
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateFittingLocation (state, value) {
    const message = 'Error 204: Vul een aanmeetlocatie in'
    if (value === '') {
        state.generalErrors.message.push(message)
        state.generalErrors.fittingLocation = true
    } else {
        state.generalErrors.fittingLocation = false
        state.generalErrors.message = state.generalErrors.message.filter(e => e !== message)
    }
}

/**
 * validate if fields are not empty.
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateAllFields (state) {
    state.generalAccordion.validate = !checkEmptyFields(state)
}

/**
 * Check if the fields of general are empty.
 *
 * @param {Object} state - redux state object
 * @returns {boolean} true
 */
function checkEmptyFields (state) {
    const patient = state.patientData

    state.generalErrors.number = patient.number === ''
    state.generalErrors.lastName = patient.lastName === ''
    state.generalErrors.gender = patient.gender === ''
    state.generalErrors.fittingLocation = patient.fittingLocation.title === ''
    state.generalErrors.receiveLocation = patient.receiveLocation.title === ''
    state.generalErrors.birthdate = !patient.birthdate

    return patient.number === '' || patient.lastName === '' || patient.fittingLocation.title === '' || !patient.birthdate || patient.receiveLocation.title === ''
}
