/**
 * @file Contains utilities to work with semantic version number and exposes the version of the application
 */
// @ts-expect-error This module gets generated by the build system
// It will export the version string from package.json by default
import CURRENT_VERSION_STRING from './_version'

/**
 * Returns the version number of the application in its string form
 * @returns The version string
 */
export function getVersionString(): string {
    return CURRENT_VERSION_STRING as string
}

/**
 * Semantic version regular expression
 * @see https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
 */
const SEMVER_REGEX: RegExp = /^(?<major>0|[1-9]\d*)\.(?<minor>0|[1-9]\d*)\.(?<patch>0|[1-9]\d*)(?:-(?<prerelease>(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+(?<buildMetadata>[0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/

/**
 * Semantic version
 * @see https://semver.org/spec/v2.0.0.html
 */
interface SemVerObject {
    major: number,
    minor: number,
    patch: number,
    prerelease?: string,
    buildMetadata?: string,
}

export class SemVer {
    major: number
    minor: number
    patch: number

    prerelease?: string
    buildMetadata?: string

    /**
     *
     * @param major
     * @param minor
     * @param patch
     * @param prerelease
     * @param buildMetadata
     */
    constructor (major: number, minor: number, patch: number, prerelease?: string, buildMetadata?: string) {
        this.major = major
        this.minor = minor
        this.patch = patch
        this.prerelease = prerelease
        this.buildMetadata = buildMetadata
    }

    /**
     * Create instance from an object
     */
    static fromObject({major, minor, patch, prerelease, buildMetadata}: SemVerObject): SemVer {
        return new SemVer(major, minor, patch, prerelease, buildMetadata)
    }

    /**
     * Formats the semantic version into a string
     */
    toString(): string {
        let result = `${this.major}.${this.minor}.${this.patch}`
        if (this.prerelease) {
            result += `-${this.prerelease}`
        }
        if (this.buildMetadata) {
            result += `+${this.buildMetadata}`
        }

        return result
    }

    /**
     * Creates a new instance with a copy of the data
     */
    clone(): SemVer {
        return new SemVer(this.major, this.minor, this.patch, this.prerelease, this.buildMetadata)
    }

    /**
     * Compares this version to another version
     * @param other Version to compare to
     * @return `-1` if `this < other`, `0` if `this == other`, `1` if `this > other`
     * @see https://semver.org/spec/v2.0.0.html#spec-item-11
     */
    compareTo(other: SemVer): -1 | 0 | 1 {
        // Precedence is determined by the first difference when comparing each of these identifiers
        // from left to right as follows: Major, minor, and patch versions are always compared numerically

        const majorSign = Math.sign(this.major - other.major)
        if (majorSign !== 0) {
            return majorSign as -1 | 1
        }

        const minorSign = Math.sign(this.minor - other.minor)
        if (minorSign !== 0) {
            return minorSign as -1 | 1
        }

        const patchSign =  Math.sign(this.patch - other.patch)
        if (patchSign !== 0) {
            return patchSign as -1 | 1
        }

        const thisPreRelease = this.prerelease
        const otherPreRelease = other.prerelease

        const prereleaseSign = semVerCompareAbsence(thisPreRelease, otherPreRelease)
        if (prereleaseSign !== undefined) {
            // When major, minor, and patch are equal, a pre-release version has lower precedence than a normal version
            return prereleaseSign
        } else {
            // Precedence for two pre-release versions with the same major, minor, and patch version MUST be determined by
            // comparing each dot separated identifier from left to right until a difference is found as follows:
            const thisPreParts = (thisPreRelease as string).split('.')
            const otherPreParts = (otherPreRelease as string).split('.')

            const max = Math.max(thisPreParts.length, otherPreParts.length)
            for (let i = 0; i < max; i++) {
                const thisPart = thisPreParts[i]
                const otherPart = otherPreParts[i]

                const partSign = semVerCompareAbsence(thisPart, otherPart)
                if (partSign !== undefined) {
                    // A larger set of pre-release fields has a higher precedence than a smaller set, if all of the
                    // preceding identifiers are equal.

                    // The partSign should be the inverse of semVerCompareAbsence
                    return partSign * -1 as -1 | 0 | 1
                } else {
                    const thisInt = parseInt(thisPart)
                    const otherInt = parseInt(otherPart)

                    if (!isNaN(thisInt) && !isNaN(otherInt)) {
                        // Identifiers consisting of only digits are compared numerically.
                        const partSign = Math.sign(thisInt - otherInt)
                        if (partSign !== 0) {
                            return partSign as -1 | 1
                        }
                        // continue
                    } else {
                        // Identifiers with letters or hyphens are compared lexically in ASCII sort order.
                        if (thisPart !== otherPart) {
                            return thisPart < otherPart ? -1 : 1
                        }
                        // continue
                    }
                }
            }

            return 0
        } 
    }
}

/**
 * Parses a semantic version string in the form of `major.minor.patch-prerelease+buildMetadata`
 * @param versionString The version string to parse
 * @returns The parsed string or `undefined` if it could not be parsed
 */
export function parseVersionString(versionString: string): SemVer | undefined {
    const group = SEMVER_REGEX.exec(versionString)?.groups
    if (group === undefined) {
        return undefined
    } else {
        return SemVer.fromObject(group as unknown as SemVerObject)
    }
}

const CURRENT_VERSION: SemVer = parseVersionString(CURRENT_VERSION_STRING as string)
    ?? new SemVer(0, 0, 0, undefined, "fakenumber")

/**
 * Compares two values. Absence of a value in the form of `undefined` is considered being smaller than having
 * a defined value.
 *
 * If both values are not undefined, returns undefined. You should compare the values yourself
 * @param a First value to compare
 * @param b Second value to compare
 * @return `-1` if `a < b`, `0` if `a == b`, `1` if `a > b`, `undefined` if `a` and `b` are not undefined
 */
function semVerCompareAbsence(a?: unknown, b?: unknown): -1 | 0 | 1 | undefined {
    if (a !== undefined && b === undefined) {
        return -1
    } else if (a === undefined && b !== undefined) {
        return 1
    } else if (a === undefined && b === undefined) {
        return 0
    } else if (a !== undefined && b !== undefined) {
        return undefined
    }
}

/**
 * Get the parsed version string
 */
export function getVersion(): SemVer {
    return CURRENT_VERSION
}